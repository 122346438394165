import React from 'react';
import { TourStep } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useSyncSettings() {
  const {
    tours: {
      SyncSettings: { Default },
    },
  } = useLocalization();

  const pageTour: TourStep[] = [
    {
      content: <div>{Default.Step1.Text}</div>,
      placement: 'auto',
      target: '.syncBlock',
      title: Default.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  return {
    pageTour,
  };
}
