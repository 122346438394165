import React from 'react';
import { TourStep } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useAmountAutomationSettings() {
  const {
    tours: {
      ShippingAutomationSettings: { Amount },
    },
  } = useLocalization();

  const pageTour: TourStep[] = [
    {
      content: <div>{Amount.Step1.Text}</div>,
      placement: 'auto',
      target: '#shippingAutomationTitle',
      title: Amount.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Amount.Step2.Text}</div>,
      placement: 'auto',
      target: '#amountAutomation',
      title: Amount.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Amount.Step3.Text}</div>,
      placement: 'auto',
      target: '#mpAccounts',
      title: Amount.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      spotlightPadding: 10,
      styles: {
        spotlight: {
          marginTop: '-10px',
          marginLeft: '-5px',
        },
      },
    },
    {
      content: <div>{Amount.Step4.Text}</div>,
      placement: 'auto',
      target: '#totalAmount',
      title: Amount.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      spotlightPadding: 10,
      styles: {
        spotlight: {
          marginTop: '-10px',
          marginLeft: '-5px',
        },
      },
    },
    {
      content: <div>{Amount.Step5.Text}</div>,
      placement: 'auto',
      target: '#shippingMethod',
      title: Amount.Step5.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      spotlightPadding: 10,
      styles: {
        spotlight: {
          marginTop: '-10px',
          marginLeft: '-5px',
        },
      },
    },
    {
      content: <div>{Amount.Step6.Text}</div>,
      placement: 'auto',
      target: '#userProductId',
      title: Amount.Step6.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      spotlightPadding: 10,
      styles: {
        spotlight: {
          marginTop: '-10px',
          marginLeft: '-5px',
        },
      },
    },
    {
      content: <div>{Amount.Step7.Text}</div>,
      placement: 'auto',
      target: '#countryZoneRecepient',
      title: Amount.Step7.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      spotlightPadding: 10,
      styles: {
        spotlight: {
          marginTop: '-10px',
          marginLeft: '-5px',
        },
      },
    },
    {
      content: <div>{Amount.Step8.Text}</div>,
      placement: 'auto',
      target: '#crudExtended',
      title: Amount.Step8.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Amount.Step9.Text}</div>,
      placement: 'auto',
      target: '#addAmountAutomationRule',
      title: Amount.Step9.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  return {
    pageTour,
  };
}
