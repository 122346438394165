import { MpOrdersMarkingApi } from '@/Api/MpOrdersMarking/MpOrdersMarkingApi';
import { ChangeOrderPaymentStatusToPaidForSingleOrderRequest } from '@/Api/MpOrdersMarking/Requests/ChangeOrderPaymentStatusToPaidForSingleOrderRequest';
import { DateFormatType, OrderStatusFilterGroups } from '@/Enums';
import { useForm } from '@/Hooks/useFormWrapper';
import { useRequired, useShouldBeCurrency, useShouldBeMoreThan } from '@/Hooks/Validation';
import { purchasesPageAsyncActions } from '@/Pages/Purchases/services/reducer';
import { salesPageActions } from '@/Pages/Sales/services/reducer';
import { dateTimeUtils } from '@/Utils/DateTimeUtils';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

type FormData = ChangeOrderPaymentStatusToPaidForSingleOrderRequest;

export const useSingleNotPaidForm = (
  orderId: number,
  isSalesPage: boolean,
  onClose: () => void,
  openAmount: number,
) => {
  const dispatch = useDispatch();

  const methods = useForm<FormData>({
    defaultValues: {
      orderId,
      amount: openAmount,
      paymentDate: dateTimeUtils.toFormattedString(new Date(), DateFormatType.LOCAL_DATE_TIME),
    },
  });

  // rules
  const required = useRequired();
  const shouldBeCurrency = useShouldBeCurrency();
  const shouldBeMoreThan = useShouldBeMoreThan(0, 0.0000001);

  // function
  const submit = useCallback(
    (data: ChangeOrderPaymentStatusToPaidForSingleOrderRequest) => {
      MpOrdersMarkingApi.changePaymentStatusSingle({
        ...data,
        paymentDate: dateTimeUtils.localStringToUtcString(data?.paymentDate),
      }).then((response) => {
        if (isSalesPage) {
          dispatch(
            salesPageActions.removeOrdersFromStateByCurrentFilters({
              orderIds: [orderId],
              filters: OrderStatusFilterGroups.NotPaidGroup,
            }),
          );

          setTimeout(() => {
            dispatch(salesPageActions.ordersUpdate([response]));
          }, 100);
        } else {
          dispatch(purchasesPageAsyncActions.getPurchasesWithCurrentFilters());
        }

        onClose();
      });
    },
    [orderId, onClose, isSalesPage],
  );

  return {
    methods,
    submit: methods.handleSubmit(submit),
    rules: {
      required,
      shouldBeCurrency,
      shouldBeMoreThan,
    },
  };
};
