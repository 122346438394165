import React from 'react';
import { TourStep } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useProductSettings() {
  const {
    tours: {
      ProductSettings: { PageTour, CategoryTour },
    },
  } = useLocalization();

  const pageTour: TourStep[] = [
    {
      content: <div>{PageTour.Step1.Text}</div>,
      placement: 'auto',
      target: '#productSettingsTitle',
      title: PageTour.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{PageTour.Step2.Text}</div>,
      placement: 'auto',
      target: '#categoryList',
      title: PageTour.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{PageTour.Step3.Text}</div>,
      placement: 'auto',
      target: '#categoryItem',
      title: PageTour.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      styles: {
        spotlight: {
          maxWidth: '800px',
          marginLeft: '40px',
          maxHeight: '35px',
        },
      },
    },
    {
      content: <div>{PageTour.Step4.Text}</div>,
      placement: 'auto',
      target: '#addNewCategory',
      title: PageTour.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{PageTour.Step5.Text}</div>,
      placement: 'auto',
      target: '#addItemButton',
      title: PageTour.Step5.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{PageTour.Step6.Text}</div>,
      placement: 'auto',
      target: '#crudExtended',
      title: PageTour.Step6.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  const categoryTour: TourStep[] = [
    {
      content: <div>{CategoryTour.Step1.Text}</div>,
      placement: 'auto',
      target: '#categoryTitle',
      title: CategoryTour.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{CategoryTour.Step2.Text}</div>,
      placement: 'auto',
      target: '#categoryName',
      title: CategoryTour.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{CategoryTour.Step3.Text}</div>,
      placement: 'auto',
      target: '#categoryLogo',
      title: CategoryTour.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{CategoryTour.Step4.Text}</div>,
      placement: 'auto',
      target: '#buttonsWrapper',
      title: CategoryTour.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  return {
    pageTour,
    categoryTour,
  };
}
