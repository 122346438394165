import { CustomerShoppingCardModel } from '@/Models/CustomerShoppingCardModel';
import { CustomerShoppingCardTableRow } from '@/Types/CustomerShoppingCardTableRow';
import { dateTimeUtils } from '@/Utils';

const modelToAllocateTableRow = (data: CustomerShoppingCardModel): CustomerShoppingCardTableRow => {
  return {
    ...data,
    unitPriceNet: Number(data.unitPriceNet).toFixed(2),
    insertItemDate: dateTimeUtils.serverStringToServerOffsetString(data.insertItemDate),
    lastModifiedDate: dateTimeUtils.serverStringToServerOffsetString(data.lastModifiedDate),
    billingStopDate: dateTimeUtils.serverStringToServerOffsetString(data.billingStopDate),
    startDate: dateTimeUtils.serverStringToServerOffsetString(data.startDate),
    endDate: dateTimeUtils.serverStringToServerOffsetString(data.endDate),
  };
}; // modelToAllocateTableRow

export const userProductsCartMapping = {
  modelToAllocateTableRow,
};
