import { useMemo, useState } from 'react';
import { TreeItemType } from '@/Components/TreeView/TreeView';
import { treeViewUtils } from '@/Utils';
import { LogisticTypeKeys } from '@/Enums';

export function useActiveLogisticTypesTreeViewOptions() {
  const logisticTypes: TreeItemType<LogisticTypeKeys>[] = [];

  const [selectedLogisticTypes, setSelectedLogisticTypes] = useState<LogisticTypeKeys[]>(() => {
    const selectedKeys = treeViewUtils.getSelectedKeys(
      logisticTypes.map((logisticType) => ({
        ...logisticType,
        items: logisticType.items?.map((item) => item),
        selected: true,
      })),
    );

    return selectedKeys as LogisticTypeKeys[];
  });

  const activeLogisticTypesTreeViewItems = useMemo<TreeItemType<LogisticTypeKeys>[]>(() => {
    return logisticTypes.map((item) => ({ ...item, selected: true }));
  }, [logisticTypes]);

  const logisticIds = useMemo(() => selectedLogisticTypes.filter((type) => type), [selectedLogisticTypes]);

  return {
    activeLogisticTypesTreeViewItems,
    setSelectedLogisticTypes,
    logisticIds,
  };
}
