import { EditableLocalizationType, Locale } from '@/Enums';
import { TranslationModel } from '@/Models/TranslationModel';
import { ModalDataType } from '@/Types/ModalDataType';
import { PayloadAction } from '@reduxjs/toolkit';
import { TranslationAction } from '../../Types';
import { Nullable } from '@/Types';
import { TranslationSettingModel } from '@/Models/LanguageEditor/TranslationSettingModel';

export type TranslationsState = {
  translations: {
    items: TranslationModel[];
    updatedItems: {
      path: string;
      description: string;
      l_a: string;
      de: string;
      en: string;
      ru: string;
    }[];
    isModified: boolean;
    action: TranslationAction;
  };
  selectedKey: {
    value: string;
    needShow: boolean;
  };
  modalDialogs: {
    saveTranslationsModalDialog: ModalDataType<null>;
  };
  languageEditorSettings: Nullable<TranslationSettingModel>;
};

const initialState: TranslationsState = {
  translations: {
    items: [],
    updatedItems: [],
    isModified: false,
    action: {
      state: 'none',
      param: null,
    },
  },
  selectedKey: {
    value: '',
    needShow: false,
  },
  modalDialogs: {
    saveTranslationsModalDialog: {
      visible: false,
    },
  },
  languageEditorSettings: null,
};

function isFilled(
  state: TranslationsState,
  type: EditableLocalizationType,
  translation: TranslationModel,
  field: keyof TranslationModel,
): boolean {
  const translationNotEmpty = translation[field] !== undefined && translation[field]!.length > 0;
  return state.languageEditorSettings?.markLanguagesInEditor?.includes(type) ? translationNotEmpty : true;
}

const actions = {
  updateTranslationValue: (
    state: TranslationsState,
    action: PayloadAction<{ path: string; value: string; locale: Locale }>,
  ) => {
    let allValuesFilled = true;
    let translation: TranslationModel | undefined;

    const levels = action.payload.path.split('.');

    levels.forEach((level, idx) => {
      if (idx === 0) {
        translation = state.translations.items.find((t) => t.key === level);
      } else {
        translation = translation?.childTranslations.find((t) => t.key === level);
      } // if

      if (translation && idx === levels.length - 1) {
        switch (action.payload.locale) {
          case '!Descriptions':
            translation.description = action.payload.value;
            break;

          case 'de-DE':
            translation.de = action.payload.value;
            break;

          case 'ru-RU':
            translation.ru = action.payload.value;
            break;

          case 'en-EN':
            translation.en = action.payload.value;
            break;
        } // switch

        const filledDescription = isFilled(state, EditableLocalizationType.Description, translation, 'description');
        const filledDe = isFilled(state, EditableLocalizationType.deDE, translation, 'de');
        const filledEn = isFilled(state, EditableLocalizationType.enEN, translation, 'en');
        const filledRu = isFilled(state, EditableLocalizationType.ruRU, translation, 'ru');

        allValuesFilled = filledDescription && filledDe && filledEn && filledRu;

        state.translations.updatedItems.push({
          path: action.payload.path,
          description: translation.description ?? '',
          l_a: action.payload.path ?? '',
          de: translation.de ?? '',
          en: translation.en ?? '',
          ru: translation.ru ?? '',
        });
      } // if
    });

    state.translations.action = {
      state: 'updateValue',
      param: {
        paths: [action.payload.path],
        allValuesFilled: allValuesFilled,
      },
    };

    state.translations.isModified = true;
  },
  resetTranslationAction: (state: TranslationsState) => {
    state.translations.action = {
      state: 'none',
      param: null,
    };
  },
  setSelectedKey: (state: TranslationsState, action: PayloadAction<typeof initialState.selectedKey>) => {
    state.selectedKey = action.payload;
  },
  setSaveTranslationsModalDialog: (state: TranslationsState, action: PayloadAction<ModalDataType<null>>) => {
    state.modalDialogs.saveTranslationsModalDialog = action.payload;
  },
  clear: () => initialState,
};

export const translationsEditorReducerPart = {
  initialState,
  actions,
};
