import { DocumentInfoModel } from '@/Models/_Microservices/OnlineArchive/DocumentInfoModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '@/Types';
import { asyncActions } from '../../../services/asyncActions';
import { DocumentInfoTableRow } from '@/Types/_Microservices/OnlineArchive';

export type ArchiveSectionState = {
  documentInfos: DocumentInfoModel[];
  focusedDocumentInfo: Nullable<DocumentInfoModel>;
  needShowModal: boolean;
};

const initialState: ArchiveSectionState = {
  documentInfos: [],
  focusedDocumentInfo: null,
  needShowModal: false,
};

const archiveSectionSlice = createSlice({
  name: 'onlineArchivePage/archiveSection',
  initialState,
  reducers: {
    setFocusedDocumentInfo: (state, action: PayloadAction<DocumentInfoTableRow | null>) => {
      state.focusedDocumentInfo = state.documentInfos.find((document) => document.id === action.payload?.id) ?? null;
    },
    setNeedShowModal: (state, action: PayloadAction<boolean>) => {
      state.needShowModal = action.payload;
    },
    clearDocumentInfos: (state) => {
      state.documentInfos = [];
      state.focusedDocumentInfo = null;
      state.needShowModal = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getDocuments.fulfilled, (state, action) => {
        state.documentInfos = action.payload;
        state.focusedDocumentInfo = state.documentInfos[0] ?? null;
      })
      .addCase(asyncActions.updateDocuments.fulfilled, (state, action) => {
        const updatedDocumentInfo = action.payload.documentInfos[0];

        state.documentInfos = state.documentInfos.map((item) =>
          item.id === updatedDocumentInfo.id ? updatedDocumentInfo : item,
        );

        state.focusedDocumentInfo = updatedDocumentInfo;
        state.needShowModal = false;
      });
  },
});

export const archiveSectionReducer = archiveSectionSlice.reducer;
export const archiveSectionActions = archiveSectionSlice.actions;
export const archiveSectionAsyncActions = asyncActions;
