import { TranslationType } from '@/Localization/LanguageKeys';
import { DocumentInfoModel } from '@/Models/_Microservices/OnlineArchive/DocumentInfoModel';
import { DocumentInfoTableRow } from '@/Types/_Microservices/OnlineArchive';
import { DocumentInfoAllocateTableRow } from '@/Types/_Microservices/OnlineArchive/DocumentInfoAllocateTableRow';
import { dateTimeUtils, fileUtils } from '@/Utils';
import { countryTypeUtils } from '@/Utils/CountryTypesUtils';

const modelToTableRow = (data: DocumentInfoModel, translation: TranslationType): DocumentInfoTableRow => {
  const {
    Common: { FileSizes },
    Enums: { DocumentSegmentType },
  } = translation;

  const localizedDocumentSegment = data.documentSegment
    ? (DocumentSegmentType[data.documentSegment] as string)
    : undefined;

  return {
    ...data,
    localizedDocumentSegment: localizedDocumentSegment,
    convertedFileSize: fileUtils.getFileSizeString(data.fileSize ?? 0, FileSizes),
    contactCountryName: countryTypeUtils.getCountryNameByCountryCode(data.contactCountryCode, translation),
    uploadTime: dateTimeUtils.serverStringToServerOffsetString(data.uploadTime),
    allocateTime: dateTimeUtils.serverStringToServerOffsetString(data.allocateTime),
    lastModifiedTime: dateTimeUtils.serverStringToServerOffsetString(data.lastModifiedTime),
    paymentDate: dateTimeUtils.serverStringToServerOffsetString(data.paymentDate),
  };
}; // modelToTableRow

const tableRowToModel = (data: DocumentInfoTableRow | null): DocumentInfoModel | null => {
  if (!data) return null;

  return {
    ...data,
  };
}; // tableRowToModel

const modelToAllocateTableRow = (data: DocumentInfoModel): DocumentInfoAllocateTableRow => {
  return {
    id: data.id,
    documentName: data.documentName,
    documentDate: data.documentDate,
    documentFolderId: data.documentFolderId,
    remindStatus: data.remindStatus,
    fileSize: data.fileSize,
    isReaded: data.isReaded,
  };
}; // modelToAllocateTableRow

export const documentInfoMapping = {
  modelToTableRow,
  tableRowToModel,
  modelToAllocateTableRow,
};
