import React from 'react';
import { TourStep } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useDocumentTemplateSettings() {
  const {
    tours: {
      DocumentTemplateSettings: { PageTour, TemplateTour },
    },
  } = useLocalization();

  const pageTour: TourStep[] = [
    {
      content: <div>{PageTour.Step1.Text}</div>,
      placement: 'auto',
      target: '#templateTitle',
      title: PageTour.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{PageTour.Step2.Text}</div>,
      placement: 'left',
      target: '#templatesTable',
      title: PageTour.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{PageTour.Step3.Text}</div>,
      placement: 'auto',
      target: '#crudExtended',
      title: PageTour.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{PageTour.Step4.Text}</div>,
      placement: 'auto',
      target: '.dx-toolbar-after',
      title: PageTour.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      spotlightPadding: 0,
      styles: {
        spotlight: {
          maxWidth: '475px',
        },
      },
    },
    {
      content: <div>{PageTour.Step5.Text}</div>,
      placement: 'auto',
      target: '.dx-dropdownmenu-button',
      title: PageTour.Step5.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  const templateTour: TourStep[] = [
    {
      content: <div>{TemplateTour.Step1.Text}</div>,
      placement: 'auto',
      target: '#templateName',
      title: TemplateTour.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{TemplateTour.Step2.Text}</div>,
      placement: 'auto',
      target: '#savePanel',
      title: TemplateTour.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{TemplateTour.Step3.Text}</div>,
      placement: 'auto',
      target: '#savePath',
      title: TemplateTour.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{TemplateTour.Step4.Text}</div>,
      placement: 'auto',
      target: '#templateCompartment',
      title: TemplateTour.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{TemplateTour.Step5.Text}</div>,
      placement: 'auto',
      target: '#setStatusPanel',
      title: TemplateTour.Step5.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{TemplateTour.Step6.Text}</div>,
      placement: 'auto',
      target: '#templateAutomation',
      title: TemplateTour.Step6.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{TemplateTour.Step7.Text}</div>,
      placement: 'left',
      target: '#documentTemplate',
      title: TemplateTour.Step7.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  return {
    pageTour,
    templateTour,
  };
}
