import { useForm } from '@/Hooks/useFormWrapper';
import { OrderLabelPrintRow } from '@/Types';
import {
  orderLabelPrintActions,
  orderLabelPrintAsyncActions,
  orderLabelPrintModalSelectors,
} from '@/ModalWindows/OrderLabelPrintModal/services';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { useDispatch, useSelector } from 'react-redux';
import { salesPageAsyncActions } from '@/Pages/Sales/services';
import { GenerateLabelsResponse } from '@/Api/Logistics/GenerateLabelsResponse';
import { Toasts } from '@/Components/Toast/Toast';
import { useCallback } from 'react';
import { localizationUtils } from '@/Utils/localizationUtils';
import { printService } from '@/Electron';
import { useLocalization } from '@/Hooks';
import { globalSelectors } from '@/Redux/Global/selectors';

export type FormData = {
  orderLabelPrintRows: OrderLabelPrintRow[];
  filterLogistics: string[];
  filterStatus: string;
  date: string;
  timeRange: string;
};

export function useOrderLabelPrintForm(closeModalWindow: () => void) {
  const dispatch: AppDispatch = useDispatch();

  const {
    modalWindows: {
      orderLabelPrint: { LabelsPrintedSuccessToast, LabelsCreateSuccessToast },
    },
  } = useLocalization();

  const localization = useSelector(globalSelectors.localization);
  const selectedOrderLabelPrintRows = useSelector(orderLabelPrintModalSelectors.selectedOrderLabelPrintRows);
  const { setValue, register, unregister, getValues, handleSubmit } = useForm<FormData>();

  const onSubmit = (data: FormData) => {
    dispatch(orderLabelPrintActions.setFilterLogistics(data.filterLogistics));
    dispatch(orderLabelPrintActions.setFilterStatus(data.filterStatus));
  };

  const printLabels = (
    documentBase64: string,
    orderLabelIds: number[],
    isPrintPreview: boolean,
    assignPrinter: string,
  ): boolean => {
    let isSuccess = false;

    if (documentBase64 === '') return isSuccess;

    const orderIds = selectedOrderLabelPrintRows
      .filter((x) => orderLabelIds.includes(x.id))
      .map((x) => x.orderId)
      .filter((value: number, index: number, self: number[]) => self.indexOf(value) === index);

    printService
      .printDocument(documentBase64, isPrintPreview, assignPrinter)
      .then(() => {
        dispatch(orderLabelPrintAsyncActions.setPrintedStatusRequest(orderLabelIds));
        dispatch(salesPageAsyncActions.getOrdersByIds(orderIds));
        isSuccess = true;
      })
      .catch((err) => {
        console.error('print error: ', err);
      });

    return isSuccess;
  };

  const getSuccessToastMessage = useCallback(
    (trackingNumbers: string[], isPrint: boolean) => {
      if (trackingNumbers.length > 1) {
        if (isPrint) {
          return localizationUtils.insertTranslatedParamsToString(
            LabelsPrintedSuccessToast.messageMany,
            [trackingNumbers.length],
            LabelsPrintedSuccessToast.parts,
            localization,
          );
        } else {
          return localizationUtils.insertTranslatedParamsToString(
            LabelsCreateSuccessToast.messageMany,
            [trackingNumbers.length],
            LabelsCreateSuccessToast.parts,
            localization,
          );
        }
      }
      if (trackingNumbers.length === 1) {
        if (isPrint) {
          return localizationUtils.insertParams(LabelsPrintedSuccessToast.messageSingle, [trackingNumbers[0]]);
        } else {
          return localizationUtils.insertParams(LabelsCreateSuccessToast.messageSingle, [trackingNumbers[0]]);
        }
      }
      return '';
    },
    [LabelsCreateSuccessToast],
  );

  const onPrintLabels = (isPrint: boolean) => {
    const { date, timeRange } = getValues();
    const collectionDate = date && timeRange ? `${date.split('.').reverse().join('/')} ${timeRange}` : undefined;

    const orderIds = selectedOrderLabelPrintRows
      .map((x) => x.orderId)
      .filter((value: number, index: number, self: number[]) => self.indexOf(value) === index);

    dispatch(orderLabelPrintAsyncActions.generateLabelsRequest({ orderIds, collectionDate })).then((response) => {
      dispatch(salesPageAsyncActions.getOrdersByIds(orderIds));
      const labelsResponse = response.payload as GenerateLabelsResponse;

      const trackingNumbers: string[] = [];
      labelsResponse.labels.forEach((label) => {
        label.labels.forEach((x) => {
          x.trackingNumbers.forEach((trackingNumber) => {
            trackingNumbers.push(trackingNumber);
          });
        });

        if (isPrint) {
          Toasts.showSuccess({
            title: LabelsPrintedSuccessToast.title,
            text: getSuccessToastMessage(trackingNumbers, isPrint),
          });
        } else {
          Toasts.showSuccess({
            title: LabelsCreateSuccessToast.title,
            text: getSuccessToastMessage(trackingNumbers, isPrint),
          });
        }

        const orderLabelIds = label.labels.filter((x) => x.isCreated).map((x) => x.orderLabelId);

        if (isPrint && printLabels(label.pdfLabels, orderLabelIds, label.isPrintPreview, label.assignPrinter)) {
          closeModalWindow();
        }
      });
    });
  };

  return {
    methods: {
      setValue,
      register,
      unregister,
      getValues,
      onSubmit: handleSubmit(onSubmit),
      onPrintLabels,
    },
  };
}
