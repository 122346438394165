import { useMemo } from 'react';
import { PagesRouting } from '@/Routing';
import { globalHistory } from '@/GlobalHistory';
import { useAccountSettings } from '../modules/Settings/AccountSettings/AccountSettings';
import { useAddressBookSettings } from '../modules/Settings/AddressBookSettings/AddressBookSettings';
import { useMandatSettings } from '../modules/Settings/MandatSettings/MandatSettings';
import { useSyncSettings } from '../modules/Settings/SyncSettings/SyncSettings';
import { useOrderAutomationSettings } from '../modules/Settings/OrderAutomationSettings/OrderAutomationSettings';
import { usePackageSettings } from '../modules/Settings/PackageSettings/PackageSettings';
import { useMarketplaceSettings } from '../modules/Settings/MarketplaceSettings/MarketplaceSettings';
import { useAmountAutomationSettings } from '../modules/Settings/ShippingAutomationSettings/AmountAutomationSettings';
import { useCategoryAutomationSettings } from '../modules/Settings/ShippingAutomationSettings/CategoryAutomationSettings';
import { usePackagingAutomationSettings } from '../modules/Settings/ShippingAutomationSettings/PackagingAutomationSettings';
import { useMixCategoryAutomationSettings } from '../modules/Settings/ShippingAutomationSettings/MixCategoryAutomationSettings';
import { useGeneralArchiveSettings } from '../modules/Settings/OnlineArchiveSettings/GeneralArchiveSettings';
import { useDocumentCategoriesSettings } from '../modules/Settings/OnlineArchiveSettings/DocumentCategoriesSettings';
import { useAllocateSettings } from '../modules/Settings/OnlineArchiveSettings/AllocateSettings';
import { useAccountChartSettings } from '../modules/Settings/OnlineArchiveSettings/AccountChartSettings';
import { useUploadTemplatesSettings } from '../modules/Settings/OnlineArchiveSettings/UploadTemplatesSettings';
import { useScanDocumentsSettings } from '../modules/Settings/OnlineArchiveSettings/ScanDocumentsSettings';
import { useProductSettings } from '../modules/Settings/ProductSettings/ProductSettings';
import { useDocumentTemplateSettings } from '../modules/Settings/DocumentTemplateSettings/DocumentTemplateSettings';
import { useSales } from '../modules/Sales/Sales';
import { useEmailTemplateSettings } from '../modules/Settings/EmailTemplateSettings/EmailTemplateSettings';
import { useWarehouseSettings } from '../modules/Settings/WarehouseSettings/WarehouseSettings';

export function useTours(runTour: boolean, currentTour: string) {
  const route = globalHistory.location.pathname;

  // Main
  const sales = useSales();

  // Settings
  const accountSettings = useAccountSettings();
  const addressBookSettings = useAddressBookSettings();
  const mandatSettings = useMandatSettings();
  const syncSettings = useSyncSettings();
  const orderAutomationSettings = useOrderAutomationSettings();
  const packageSettings = usePackageSettings();
  const emailTemplateSettigns = useEmailTemplateSettings();
  const marketplaceSettings = useMarketplaceSettings();
  const productSettings = useProductSettings();
  const documentTemplateSettings = useDocumentTemplateSettings();
  const warehouseSettings = useWarehouseSettings();

  const amountAutomationSettings = useAmountAutomationSettings();
  const categoryAutomationSettings = useCategoryAutomationSettings();
  const packagingAutomationSettings = usePackagingAutomationSettings();
  const mixCategoryAutomationSettings = useMixCategoryAutomationSettings();

  const generalArchiveSettings = useGeneralArchiveSettings();
  const documentCategoriesSettings = useDocumentCategoriesSettings();
  const allocateSettings = useAllocateSettings();
  const uploadTemplatesSettings = useUploadTemplatesSettings();
  const accountChartSettings = useAccountChartSettings();
  const scanDocumentsSettings = useScanDocumentsSettings();

  return useMemo(() => {
    const currentPageTours = {
      // Main
      [PagesRouting.MainPages.SalesPage.Sales]: sales,

      // Settings
      [PagesRouting.SettingsPages.AccountSettings]: accountSettings,
      [PagesRouting.SettingsPages.AddressBookSettings]: addressBookSettings,
      [PagesRouting.SettingsPages.MandatsSettings]: mandatSettings,
      [PagesRouting.SettingsPages.SynchronizeSettings]: syncSettings,
      [PagesRouting.SettingsPages.OrderAutomationSettings]: orderAutomationSettings,
      [PagesRouting.SettingsPages.PackagesSettings]: packageSettings,
      [PagesRouting.SettingsPages.MarketplacesSettings]: marketplaceSettings,
      [PagesRouting.SettingsPages.DocumentTemplateSettings]: documentTemplateSettings,
      [PagesRouting.SettingsPages.EmailTemplateSettings]: emailTemplateSettigns,
      [PagesRouting.SettingsPages.WarehouseSettings]: warehouseSettings,

      [PagesRouting.SettingsPages.ProductsSettings.Categories]: productSettings,

      [PagesRouting.SettingsPages.ShippingAutomationSettings.AmountAutomation]: amountAutomationSettings,
      [PagesRouting.SettingsPages.ShippingAutomationSettings.CategoryAutomation]: categoryAutomationSettings,
      [PagesRouting.SettingsPages.ShippingAutomationSettings.PackagingAutomation]: packagingAutomationSettings,
      [PagesRouting.SettingsPages.ShippingAutomationSettings.MixCategoryAutomation]: mixCategoryAutomationSettings,

      [PagesRouting.SettingsPages.OnlineArchiveSetting.General]: generalArchiveSettings,
      [PagesRouting.SettingsPages.OnlineArchiveSetting.DocumentCategories]: documentCategoriesSettings,
      [PagesRouting.SettingsPages.OnlineArchiveSetting.AllocateCategories]: allocateSettings,
      [PagesRouting.SettingsPages.OnlineArchiveSetting.UploadTemplates]: uploadTemplatesSettings,
      [PagesRouting.SettingsPages.OnlineArchiveSetting.AccountChart]: accountChartSettings,
      [PagesRouting.SettingsPages.OnlineArchiveSetting.ScanDocuments]: scanDocumentsSettings,
    }[route];

    if (!currentPageTours) return [];

    return currentPageTours[currentTour as keyof typeof currentPageTours];
  }, [route, runTour, currentTour]);
}
