import React from 'react';
import styles from './HelpSidebar.scss';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { tourSelectors } from '@/Redux/Tours/selectors';
import { tourActions } from '@/Redux/Tours/reducer';
import { Button } from '@/Components/Controls/Button/Button';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';

export const HelpSidebar = () => {
  const dispatch = useDispatch();

  const tourStarted = useSelector(tourSelectors.runTour);
  const openSidebar = useSelector(tourSelectors.openSidebar);

  const closeSidebar = () => {
    dispatch(tourActions.setOpenSidebar(false));
  };

  return (
    <div className={cn(styles.sidebar, { [styles.open]: openSidebar, [styles.tourStarted]: tourStarted })}>
      <div className={styles.sidebarBlock}>
        <div className={styles.sidebarHeader}>
          <h3>Help & Support</h3>
          <button className={styles.closeBtn} onClick={closeSidebar}>
            &times;
          </button>
        </div>

        <div className={styles.sidebarContent}>
          <Button
            theme={BUTTON_THEMES.FILLED_BLUE}
            onClick={() => {
              if (!tourStarted) {
                dispatch(tourActions.setRun({ run: true }));
              }
            }}
            text={'Start tour'}
          />
        </div>
      </div>
    </div>
  );
};
