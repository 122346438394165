import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserProductDuplicatedModel, GeneratorProductCountryModel, GeneratorProductModel } from '@/Models';
import { GeneratorProductModalType, InternationalType } from '@/Enums';
import { Pair } from '@/Types';
import { asyncActions } from './asyncActions';

function shouldUpdateProduct(
  product: GeneratorProductModel,
  model: GeneratorProductModel,
  selectedProductUuid: string,
) {
  if (selectedProductUuid !== '') {
    return product.uuid === selectedProductUuid;
  }
  return product.id === model.id;
}

const updateGeneratorProduct = (
  templates: GeneratorProductCountryModel[],
  model: GeneratorProductModel,
  selectedTemplateUuid = '',
) => {
  const templateCountry = templates.find((x) => x.country === model.countryCode);

  if (templateCountry) {
    const templateLogistic = templateCountry.logistics.find((x) => x.logisticId == model.logisticTypeId);
    if (templateLogistic) {
      templateLogistic.templates = templateLogistic.templates.map((product) => {
        return shouldUpdateProduct(product, model, selectedTemplateUuid) ? model : product;
      });
    }
  }
};

export type GeneratorProductState = {
  templates: GeneratorProductCountryModel[];
  selectedTemplateUuid: string;
  logisticTypeOptions: Pair<string | number>[];
  modalWindowType: GeneratorProductModalType;
  duplicatedProducts: UserProductDuplicatedModel[];
};

const initialState: GeneratorProductState = {
  templates: [],
  selectedTemplateUuid: '',
  logisticTypeOptions: [],
  modalWindowType: GeneratorProductModalType.None,
  duplicatedProducts: [],
};

const generationProductsSlice = createSlice({
  name: 'GeneratorProductsState',
  initialState,
  reducers: {
    setLogisticTypes(state, action: PayloadAction<Pair<string | number>[]>) {
      state.logisticTypeOptions = action.payload;
    },
    setSelectedTemplateUuid(state, action: PayloadAction<string>) {
      state.selectedTemplateUuid = action.payload;
    },
    addGeneratorProduct(state, action: PayloadAction<GeneratorProductModel>) {
      const templateCountry = state.templates.find((x) => x.country === action.payload.countryCode);
      if (templateCountry) {
        let templateLogistic = templateCountry.logistics.find((x) => x.logisticId == action.payload.logisticTypeId);
        if (templateLogistic) {
          templateLogistic.templates.push(action.payload);
        } else {
          templateLogistic = {
            country: action.payload.countryCode,
            logisticId: Number(action.payload.logisticTypeId),
            templates: [action.payload],
          };
          templateCountry.logistics.push(templateLogistic);
        }
      }
    },
    deleteGeneratorProduct(state, action: PayloadAction<GeneratorProductModel>) {
      const templateCountry = state.templates.find((x) => x.country === action.payload.countryCode);
      if (templateCountry) {
        const templateLogistic = templateCountry.logistics.find((x) => x.logisticId == action.payload.logisticTypeId);
        if (templateLogistic) {
          templateLogistic.templates = templateLogistic.templates.filter((x) => x.uuid != action.payload.uuid);

          if (templateLogistic.templates.length === 0) {
            templateCountry.logistics = templateCountry.logistics.filter((x) => x.country != templateLogistic.country);
          }
        }
      }
    },
    resetToDomesticGeneratorProduct(state, action: PayloadAction<GeneratorProductModel>) {
      const templateCountry = state.templates.find((x) => x.country === action.payload.countryCode);
      if (templateCountry) {
        const templateLogistic = templateCountry.logistics.find((x) => x.logisticId == action.payload.logisticTypeId);
        if (templateLogistic) {
          const template = templateLogistic.templates.find((x) => x.uuid === action.payload.uuid);
          if (template) {
            template.internationalType = InternationalType.Domestic;
            template.countryZoneRecepientId = '';
          }
        }
      }
    },
    openModal(state, action: PayloadAction<GeneratorProductModalType>) {
      state.modalWindowType = action.payload;
    },
    closeModal(state) {
      state.modalWindowType = GeneratorProductModalType.None;
    },
    clearData(state) {
      state.logisticTypeOptions = [];
      state.templates = [];
      state.modalWindowType = GeneratorProductModalType.None;
      state.selectedTemplateUuid = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getGeneratorProductsRequest.fulfilled, (state, action) => {
        state.templates = action.payload.templates.sort((x, y) => (x.countryName > y.countryName ? 1 : -1));
      })
      .addCase(asyncActions.createGeneratorProductRequest.fulfilled, (state, action) => {
        updateGeneratorProduct(state.templates, action.payload.template, state.selectedTemplateUuid);
        state.selectedTemplateUuid = '';
      })
      .addCase(asyncActions.updateGeneratorProductRequest.fulfilled, (state, action) => {
        updateGeneratorProduct(state.templates, action.payload.template);
        state.selectedTemplateUuid = '';
      })
      .addCase(asyncActions.deleteGeneratorProductRequest.fulfilled, (state, action) => {
        state.templates.forEach((x) => {
          x.logistics.forEach((y) => (y.templates = y.templates.filter((z) => z.id !== action.payload)));
          x.logistics = x.logistics.filter((y) => y.templates.length !== 0);
        });
      })
      .addCase(asyncActions.generateProductsRequest.fulfilled, (state, action) => {
        if (action.payload.duplicatedProducts.length > 0) {
          state.duplicatedProducts = action.payload.duplicatedProducts;
          state.modalWindowType = GeneratorProductModalType.UserProductDuplicated;
        } else {
          state.duplicatedProducts = [];
        }

        updateGeneratorProduct(state.templates, action.payload.template);
      });
  },
});

const { actions, reducer } = generationProductsSlice;

export const generatorProductsActions = actions;
export const generatorProductsReducer = reducer;
export const generatorProductsAsyncActions = asyncActions;
