import { useCallback } from 'react';
import { OnCellPrepared } from '@/Types';
import styles from '../DataTable.scss';

export const useOnCellPrepared = <TData>(rowKey?: '' | keyof TData, mergeColumnsMap?: { [key: string]: any }) => {
  const onCellPrepared = useCallback(
    (e: OnCellPrepared) => {
      switch (e.rowType) {
        case 'data': {
          e.cellElement?.classList.add(styles.cell_data);
          break;
        }
        case 'filter': {
          e.cellElement?.classList.add(styles.cell_filter);
          break;
        }
        case 'header': {
          e.cellElement?.classList.add(styles.cell_header);
          break;
        }
      }

      if (!rowKey || !mergeColumnsMap) return;

      if (mergeColumnsMap[e.column?.name ?? ''] && e.rowIndex != undefined) {
        const nextRow = e.component?.getVisibleRows()[e.rowIndex + 1];
        const nextBorderHidden = e.cellElement?.classList.contains(styles.cell__border);

        if (nextRow?.data[rowKey] == e.row?.data[rowKey] && !nextBorderHidden) {
          e.cellElement?.classList.add(styles.cell__border);
        }

        const prevRow = e.component?.getVisibleRows()[e.rowIndex - 1];
        const prevBorderHidden = e.cellElement?.classList.contains(styles.cell__color);

        if (prevRow?.data[rowKey] == e.row?.data[rowKey] && !prevBorderHidden) {
          e.cellElement?.classList.add(styles.cell__color);
        }
      }
    },
    [rowKey, mergeColumnsMap],
  );

  return {
    onCellPrepared,
  };
};
