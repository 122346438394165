import { ClassNameProps, ModalProps } from '@/Types';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './SynchModal.scss';
import { MpOrdersApi } from '@/Api/MpOrders';
import { MpFeedbacksApi } from '@/Api/MpFeedback';
import { useMpAccountTreeViewOptions } from '@/Hooks/TreeViewOptions/';
import { LogisticTypeKeys, MarketplaceType } from '@/Enums';
import { useLocalization, useTabs } from '@/Hooks';
import { useHistory } from 'react-router';
import { PagesRouting } from '@/Routing';
import cn from 'classnames';
import { Button } from '@/Components/Controls/Button/Button';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { Modal } from '@/Components/Modal/Modal';
import { ButtonsWrapper } from '@/Components/Forms/ButtonsSection/ButtonsSection';
import { TreeView } from '@/Components/TreeView/TreeView';
import { Tabs } from '@/Components/Tabs/Tabs';
import { TabList } from '@/Components/Tabs/modules/TabList/TabList';
import { TabItem } from '@/Components/Tabs/modules/TabItem/TabItem';
import { TabsPanel } from '@/Components/Tabs/modules/TabPanel/TabPanel';
import { useActiveLogisticTypesTreeViewOptions } from '@/Hooks/TreeViewOptions/useActiveLogisticTypesTreeViewOptions';
import Countdown from 'react-countdown';
import { BackgroundTasksApi } from '@/Api/BackgroundTasks/BackgroundTasksApi';
import { GetLastSynchDateResponse } from '@/Api/BackgroundTasks/Responses/GetLastSynchDateResponse';
import { dateTimeUtils } from '@/Utils';
import { UpdateByFileTab } from '@/Components/SideMenu/modules/UpdateFromFileTab/UpdateByFileTab';

type Props = ModalProps & ClassNameProps;

export function SynchModal({ closeModalWindow }: Props) {
  const {
    modalWindows: {
      mpSynchronization: { NoAccountsMessage, ModalTabs },
    },
    buttons: { No, Yes },
    modules: { Synchronize },
  } = useLocalization();

  const { mpTreeViewItems, mpIds, setSelectedAccountIds } = useMpAccountTreeViewOptions(
    undefined,
    [MarketplaceType.Manual, MarketplaceType.Market],
    true,
    true,
  );

  const {
    activeLogisticTypesTreeViewItems,
    setSelectedLogisticTypes,
    logisticIds,
  } = useActiveLogisticTypesTreeViewOptions();

  const history = useHistory();
  const noMpAccountsEnabled = mpTreeViewItems.length <= 0;
  const { setActiveTab, isActive } = useTabs(0);

  const synchronize = () => {
    Promise.all([
      MpOrdersApi.synchOrders({
        mpAccountIds: mpIds,
      }),
      MpFeedbacksApi.synchFeedbacks({
        mpAccountIds: mpIds,
      }),
    ]).then(() => {
      closeModalWindow();
    });
  };

  const synchronizeLogistics = () => {
    MpOrdersApi.synchShippingsStatusesForOrders({ logistics: logisticIds }).then(() => {
      closeModalWindow();
    });
  };

  const tabs = useMemo(() => {
    return [ModalTabs.Marketplace, ModalTabs.Logistic, ModalTabs.UpdateByFile];
  }, [ModalTabs]);

  const [synchDate, setSynchDate] = useState({
    lastMarketplaceSynchDate: null,
    lastLogisticsSynchDate: null,
  });

  const getLastSynchDate = async () => {
    const lastSynchDate = await BackgroundTasksApi.getLastSynchDate();

    Object.keys(synchDate).forEach((key) => {
      if (lastSynchDate[key as keyof GetLastSynchDateResponse]) {
        setSynchDate((prevState) => ({
          ...prevState,
          [key]: dateTimeUtils.convertHoursToSeconds(lastSynchDate[key as keyof GetLastSynchDateResponse]),
        }));
      }
    });
  };

  useEffect(() => {
    getLastSynchDate();
  }, []);

  return (
    <Modal className={cn(styles.modal, { [styles.modal_noAccounts]: noMpAccountsEnabled })} onClose={closeModalWindow}>
      <Tabs>
        <TabList>
          {tabs.map((tabName, idx) => (
            <TabItem
              className={styles.tabItem}
              idx={idx}
              tabName={tabName.title}
              key={tabName.title}
              isActive={isActive(idx)}
              onClick={(idx) => {
                setActiveTab(idx);
                getLastSynchDate();
              }}
            />
          ))}
        </TabList>

        <TabsPanel isActive={isActive(0)} className={styles.tabsPanel}>
          {noMpAccountsEnabled ? (
            <>
              <p className={styles.noAccountsMessage}>{NoAccountsMessage}</p>
              <ButtonsWrapper>
                <Button
                  text={Yes}
                  onClick={() => history.push(PagesRouting.SettingsPages.MarketplacesSettings)}
                  theme={BUTTON_THEMES.SUCCESS}
                />
                <Button text={No} onClick={closeModalWindow} theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} />
              </ButtonsWrapper>
            </>
          ) : (
            <div className={styles.tabContent}>
              {synchDate.lastMarketplaceSynchDate && (
                <div className={styles.timer}>
                  <Countdown
                    daysInHours={true}
                    date={Date.now() + synchDate.lastMarketplaceSynchDate * 1000}
                    onComplete={() => setSynchDate((prev) => ({ ...prev, lastMarketplaceSynchDate: null }))}
                  />
                </div>
              )}

              <h3 className={styles.title}>{Synchronize}</h3>
              <p className={styles.text}>{ModalTabs.Marketplace.text}</p>

              <TreeView
                className={styles.treeView}
                checkboxesMode={'selectAll'}
                selectionMode={'multiple'}
                onItemClick={() => null}
                items={mpTreeViewItems}
                width={350}
                onSelectionChanged={(ev) => setSelectedAccountIds(ev.ids as string[])}
              />
              <div className={styles.buttonsWrapper}>
                <Button
                  autoFocus={true}
                  className={styles.wideButton}
                  disabled={!mpIds?.length || !!synchDate.lastMarketplaceSynchDate}
                  text={Synchronize}
                  theme={BUTTON_THEMES.SUCCESS}
                  onClick={synchronize}
                />
              </div>
            </div>
          )}
        </TabsPanel>

        <TabsPanel isActive={isActive(1)} className={styles.tabsPanel}>
          <div className={styles.tabContent}>
            {synchDate.lastLogisticsSynchDate && (
              <div className={styles.timer}>
                <Countdown
                  daysInHours={true}
                  date={Date.now() + synchDate.lastLogisticsSynchDate * 1000}
                  onComplete={() => setSynchDate((prev) => ({ ...prev, lastLogisticsSynchDate: null }))}
                />
              </div>
            )}

            <h3 className={styles.title}>{Synchronize}</h3>
            <p className={styles.text}>{ModalTabs.Logistic.text}</p>
            <TreeView
              className={styles.treeView}
              checkboxesMode={'selectAll'}
              selectionMode={'multiple'}
              onItemClick={() => null}
              items={activeLogisticTypesTreeViewItems}
              width={350}
              onSelectionChanged={(ev) => setSelectedLogisticTypes(ev.ids as LogisticTypeKeys[])}
            />
            <div className={styles.buttonsWrapper}>
              <Button
                className={styles.wideButton}
                disabled={!mpIds?.length || !!synchDate.lastLogisticsSynchDate}
                text={Synchronize}
                theme={BUTTON_THEMES.SUCCESS}
                onClick={synchronizeLogistics}
              />
            </div>
          </div>
        </TabsPanel>

        <TabsPanel isActive={isActive(2)} className={styles.tabsPanel}>
          <UpdateByFileTab closeModalWindow={closeModalWindow} />
        </TabsPanel>
      </Tabs>
    </Modal>
  );
}
