import { ColumnType } from '@/Types';
import { DataGrid } from 'devextreme-react/data-grid';
import { dateTimeUtils, stringUtils } from '@/Utils';

export function getColumnRender(columnType: ColumnType, value: string): string | boolean | number {
  if (+value == 0 && columnType === ColumnType.Currency) return (0).toCurrencyString();
  // devexpress converts undefined to NaN string value
  if (value == 'NaN' && columnType === ColumnType.Currency) return (0).toCurrencyString();

  if (!value || value == 'NaN') return '';

  switch (columnType) {
    case ColumnType.Decimal:
      return Number(stringUtils.normalizeToNumberString(value)).toDecimalString();

    case ColumnType.Currency:
      return Number(stringUtils.normalizeToNumberString(value)).toCurrencyString();

    case ColumnType.Boolean:
      return Boolean(value);

    case ColumnType.Number:
      return Number(value).toIntegerString();

    case ColumnType.DateTime:
      return dateTimeUtils.serverStringToLocalString(value, false);

    case ColumnType.Date:
      return dateTimeUtils.serverStringToLocalString(value, true);

    default:
      return String(value || '');
  }
} // getColumnRender

export function getSelectedDataByKey<TData>(
  dataGrid: DataGrid<TData, keyof TData> | undefined,
  rowKey: keyof TData | '',
): TData[] {
  if (!dataGrid?.instance || !rowKey) return [];

  const selectedRowsData = dataGrid.instance.getSelectedRowsData();
  return Array.from(new Set(selectedRowsData));
} // getSelectedDataByKey

export const getCalculateDisplayValue = <TData extends object>(
  row: TData,
  columnType?: ColumnType,
  fieldName?: keyof TData,
) => {
  if (!fieldName) {
    return;
  }

  switch (columnType) {
    case ColumnType.Currency:
      return (+row[fieldName]).toCurrencyString();

    case ColumnType.Weight:
      return (+row[fieldName]).toWeightString();

    case ColumnType.DateTime:
      return row[fieldName];

    case ColumnType.Date:
      return row[fieldName];

    default:
      return row[fieldName];
  }
}; // getCalculateCellValue

export const getTypeByColumnInfo = (columnType?: ColumnType) => {
  switch (columnType) {
    case ColumnType.String:
    case ColumnType.Currency:
    case ColumnType.Weight:
      return 'string';

    case ColumnType.DateTime:
      return 'datetime';

    case ColumnType.Date:
      return 'date';

    case ColumnType.Number:
      return 'number';

    case ColumnType.Boolean:
      return 'boolean';

    default:
      return 'string';
  }
}; // getTypeByColumnInfo
