import React, { useEffect, useRef } from 'react';
import { ClassNameProps } from '@/Types';
import styles from './FormValue.scss';
import cn from 'classnames';

type Props = {
  value?: string | number;
} & ClassNameProps;

export const FormValue = ({ value = '', className }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && typeof ref.current.scroll == 'function') {
      ref.current.scroll({ left: 0 });
    }
  }, [value]);

  return (
    <div ref={ref} className={cn(styles.formValue, className)} title={String(value)}>
      <span>{value}</span>
    </div>
  );
};
