import React from 'react';
import { TourStep } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useWarehouseSettings() {
  const {
    tours: {
      WarehouseSettings: {
        PageTour,
        WarehouseTypeTour,
        WarehouseLocationTour,
        WarehouseTemplateTour,
        TemplateUnitTour,
      },
    },
  } = useLocalization();

  const pageTour: TourStep[] = [
    {
      content: <div>{PageTour.Step1.Text}</div>,
      placement: 'auto',
      target: '#warehouseTitle',
      title: PageTour.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{PageTour.Step2.Text}</div>,
      placement: 'auto',
      target: '#warehouseTypes',
      title: PageTour.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{PageTour.Step3.Text}</div>,
      placement: 'auto',
      target: '#warehouseType',
      title: PageTour.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{PageTour.Step4.Text}</div>,
      placement: 'auto',
      target: '#addWarehouseType',
      title: PageTour.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{PageTour.Step5.Text}</div>,
      placement: 'auto',
      target: '#crudExtended',
      title: PageTour.Step5.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{PageTour.Step6.Text}</div>,
      placement: 'auto',
      target: '#warehouseLocations',
      title: PageTour.Step6.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{PageTour.Step7.Text}</div>,
      placement: 'auto',
      target: '#warehouseLocation',
      title: PageTour.Step7.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{PageTour.Step8.Text}</div>,
      placement: 'auto',
      target: '#addWarehouseLocation',
      title: PageTour.Step8.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{PageTour.Step9.Text}</div>,
      placement: 'auto',
      target: '#warehouseTemplates',
      title: PageTour.Step9.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{PageTour.Step10.Text}</div>,
      placement: 'auto',
      target: '#warehouseTemplate',
      title: PageTour.Step10.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{PageTour.Step11.Text}</div>,
      placement: 'auto',
      target: '#addWarehouseTemplate',
      title: PageTour.Step11.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  const warehouseTypeTour: TourStep[] = [
    {
      content: <div>{WarehouseTypeTour.Step1.Text}</div>,
      placement: 'auto',
      target: '#warehouseTypeTitle',
      title: WarehouseTypeTour.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{WarehouseTypeTour.Step2.Text}</div>,
      placement: 'auto',
      target: '#warehouseTypeName',
      title: WarehouseTypeTour.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{WarehouseTypeTour.Step3.Text}</div>,
      placement: 'auto',
      target: '#warehouseTypeColor',
      title: WarehouseTypeTour.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{WarehouseTypeTour.Step4.Text}</div>,
      placement: 'auto',
      target: '#warehouseTypeActive',
      title: WarehouseTypeTour.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{WarehouseTypeTour.Step5.Text}</div>,
      placement: 'auto',
      target: '#warehouseTypeFlags',
      title: WarehouseTypeTour.Step5.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{WarehouseTypeTour.Step6.Text}</div>,
      placement: 'auto',
      target: '#buttonsWrapper',
      title: WarehouseTypeTour.Step6.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  const warehouseLocationTour: TourStep[] = [
    {
      content: <div>{WarehouseLocationTour.Step1.Text}</div>,
      placement: 'auto',
      target: '#warehouseLocationTitle',
      title: WarehouseLocationTour.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{WarehouseLocationTour.Step2.Text}</div>,
      placement: 'auto',
      target: '#warehouseLocationActive',
      title: WarehouseLocationTour.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{WarehouseLocationTour.Step3.Text}</div>,
      placement: 'auto',
      target: '#locationInfo',
      title: WarehouseLocationTour.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{WarehouseLocationTour.Step4.Text}</div>,
      placement: 'auto',
      target: '#addressInfo',
      title: WarehouseLocationTour.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{WarehouseLocationTour.Step5.Text}</div>,
      placement: 'auto',
      target: '#buttonsWrapper',
      title: WarehouseLocationTour.Step5.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  const warehouseTemplateTour: TourStep[] = [
    {
      content: <div>{WarehouseTemplateTour.Step1.Text}</div>,
      placement: 'auto',
      target: '#warehouseTemplateTitle',
      title: WarehouseTemplateTour.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{WarehouseTemplateTour.Step2.Text}</div>,
      placement: 'auto',
      target: '#warehouseTemplateName',
      title: WarehouseTemplateTour.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{WarehouseTemplateTour.Step3.Text}</div>,
      placement: 'auto',
      target: '#templateItems',
      title: WarehouseTemplateTour.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{WarehouseTemplateTour.Step4.Text}</div>,
      placement: 'auto',
      target: '#templateBlock',
      title: WarehouseTemplateTour.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{WarehouseTemplateTour.Step5.Text}</div>,
      placement: 'auto',
      target: '#createTemplateItem',
      title: WarehouseTemplateTour.Step5.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{WarehouseTemplateTour.Step6.Text}</div>,
      placement: 'auto',
      target: '#buttonsWrapper',
      title: WarehouseTemplateTour.Step6.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  const templateUnitTour: TourStep[] = [
    {
      content: <div>{TemplateUnitTour.Step1.Text}</div>,
      placement: 'auto',
      target: '#templateUnitTitle',
      title: TemplateUnitTour.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{TemplateUnitTour.Step2.Text}</div>,
      placement: 'auto',
      target: '#templateUnitName',
      title: TemplateUnitTour.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  return {
    pageTour,
    warehouseTypeTour,
    warehouseLocationTour,
    warehouseTemplateTour,
    templateUnitTour,
  };
}
