import React from 'react';
import { TourStep } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useEmailTemplateSettings() {
  const {
    tours: {
      EmailTemplateSettings: { Default, Template },
    },
  } = useLocalization();

  const pageTour: TourStep[] = [
    {
      content: <div>{Default.Step1.Text}</div>,
      placement: 'auto',
      target: '#templateTitle',
      title: Default.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Default.Step2.Text}</div>,
      placement: 'auto',
      target: '#templatesTable',
      title: Default.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Default.Step3.Text}</div>,
      placement: 'auto',
      target: '#crudExtended',
      title: Default.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Default.Step4.Text}</div>,
      placement: 'auto',
      target: '.dx-toolbar-after',
      title: Default.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      spotlightPadding: 0,
      styles: {
        spotlight: {
          maxWidth: '500px',
        },
      },
    },
    {
      content: <div>{Default.Step5.Text}</div>,
      placement: 'auto',
      target: '.dx-dropdownmenu-button',
      title: Default.Step5.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  const templateTour: TourStep[] = [
    {
      content: <div>{Template.Step1.Text}</div>,
      placement: 'auto',
      target: '#templateName',
      title: Template.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Template.Step2.Text}</div>,
      placement: 'auto',
      target: '#emailData',
      title: Template.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Template.Step3.Text}</div>,
      placement: 'auto',
      target: '#sendingEmail',
      title: Template.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Template.Step4.Text}</div>,
      placement: 'auto',
      target: '#attachFiles',
      title: Template.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Template.Step5.Text}</div>,
      placement: 'auto',
      target: '#compartment',
      title: Template.Step5.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Template.Step6.Text}</div>,
      placement: 'auto',
      target: '#templateAutomation',
      title: Template.Step6.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Template.Step7.Text}</div>,
      placement: 'left',
      target: '#dxReportDesignerId',
      title: Template.Step7.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  return {
    pageTour,
    templateTour,
  };
}
