import React from 'react';
import styles from './WithModules.scss';
import { MainContent } from '@/Components/MainContent/MainContent';
import { Header } from '@/Components/Header/Header';
import { SideMenu } from '@/Components/SideMenu/SideMenu';
import { HelpSidebar } from '@/Components/HelpSidebar/HelpSidebar';

export function withModules<Props extends object>(Component: React.FC<Props>): React.FC<Props> {
  return function withModulesComponent(props: Props) {
    return (
      <div className={styles.wrapper}>
        <Header className={styles.topMenu} />
        <SideMenu className={styles.sideBar} />
        <MainContent className={styles.main}>
          <Component {...props} className={styles.component} />
          <HelpSidebar />
        </MainContent>
      </div>
    );
  };
}
