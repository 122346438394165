import { DateRangeParam } from '@/Api';
import { AppUserSettingNameType, DateFormatType } from '@/Enums';
import { UserSettingModel } from '@/Models';
import moment from 'moment-timezone';

let globalTimeZone: string;

/* ---- Default settings ---- */
function setDefaultSettings(settings?: { [key in AppUserSettingNameType]?: UserSettingModel }) {
  const timeZoneSetting = settings?.[AppUserSettingNameType.TimeZone];
  const dateFormatSetting = settings?.[AppUserSettingNameType.DateFormat];
  const timeFormatSetting = settings?.[AppUserSettingNameType.TimeFormat];
  const startOfWeekSetting = settings?.[AppUserSettingNameType.FirstDayOfWeek];

  if (timeZoneSetting) {
    moment.tz.setDefault(timeZoneSetting.value);
    globalTimeZone = timeZoneSetting?.value as string;
  } // if

  moment.updateLocale('en', {
    week: {
      dow: Number(startOfWeekSetting?.value ?? 1),
    },
    longDateFormat: {
      LT: 'h:mm A', // default
      LTS: 'h:mm:ss A', // default
      L: 'MM/DD/YYYY', // default
      LL: 'YYYY-MM-DD HH:mm:ss', // server setting
      LLL: dateFormatSetting?.value ?? 'DD.MM.YYYY', // user setting or default value
      LLLL: `${dateFormatSetting?.value ?? 'DD.MM.YYYY'} ${timeFormatSetting?.value ?? 'hh:mm:ss'}`, // user setting or default value
    },
  });
} // setDefaultSettings
/* -------------------------- */

/* ---- Public date to string converters ---- */
function toFormattedString(date: Date, dateFormat: DateFormatType): string {
  return moment(date).format(dateFormat);
}

function toFormatterUtcString(date: Date): string {
  return moment.utc(date).format(DateFormatType.SERVER_DATE_TIME);
}

function convertHoursToSeconds(date: string): number {
  return moment(date, 'hh:mm:ss').diff(moment().startOf('day'), 'seconds');
}

function convertDateToHours(date: Date, isShortTime = false, withLocalTimezoneOffset = false): string {
  const originalOffset = date.getTimezoneOffset();
  const format = isShortTime ? 'HH:mm' : 'HH:mm:ss';

  return withLocalTimezoneOffset ? moment(date).zone(originalOffset).format(format) : moment(date).format(format);
}

function convertHoursToCurrentDateTime(date: string): Date {
  return moment(date, ['HH:mm:ss']).toDate();
}

function changeDateFormat(
  date: string | undefined,
  oldDateFormat: DateFormatType,
  newDateFormat: DateFormatType,
): string {
  if (!date) return '';

  return moment(date, oldDateFormat).format(newDateFormat);
}
/* ------------------------------------------ */

/* ---- Public string converters ---- */
function localStringToServerString(localString: string | undefined, isShortDate = false): string {
  if (!localString) return '';

  return moment(localString, isShortDate ? DateFormatType.LOCAL_DATE : DateFormatType.LOCAL_DATE_TIME).format(
    DateFormatType.SERVER_DATE_TIME,
  );
}

function serverStringToLocalString(serverString: string | undefined, isShortDate = false): string {
  if (!serverString) return '';

  return moment(serverString, DateFormatType.SERVER_DATE_TIME).format(
    isShortDate ? DateFormatType.LOCAL_DATE : DateFormatType.LOCAL_DATE_TIME,
  );
}

function serverStringToServerOffsetString(serverString: string | undefined | null): string {
  if (!serverString) return '';

  return moment
    .utc(serverString, DateFormatType.SERVER_DATE_TIME)
    .tz(globalTimeZone)
    .format(DateFormatType.SERVER_DATE_TIME);
}

function localStringToUtcString(localString: string | undefined, isShortDate = false): string {
  if (!localString) return '';

  return moment(localString, isShortDate ? DateFormatType.LOCAL_DATE : DateFormatType.LOCAL_DATE_TIME)
    .utc()
    .format(DateFormatType.SERVER_DATE_TIME);
} // localStringToUtcString

function utcStringToLocalString(utcString: string | null | undefined, isShortDate = false): string {
  if (!utcString || !globalTimeZone) return '';

  return moment
    .utc(utcString, DateFormatType.SERVER_DATE_TIME)
    .tz(globalTimeZone)
    .format(isShortDate ? DateFormatType.LOCAL_DATE : DateFormatType.LOCAL_DATE_TIME);
} // utcStringToLocalString

function localDateRangeToUtcString(dateRange: DateRangeParam): DateRangeParam {
  return {
    ...dateRange,
    timeFrom: localStringToUtcString(dateRange.timeFrom),
    timeTo: localStringToUtcString(dateRange.timeTo),
  };
} // localDateRangeToUtcString

function getEndOfDateString(dateString: string | undefined): string {
  if (!dateString) return '';

  return moment(dateString, DateFormatType.LOCAL_DATE).endOf('day').format(DateFormatType.LOCAL_DATE_TIME);
}

function getCountOfDaysBetweenDates(start: string, end: string, isFormatted?: boolean): number {
  const startDate = moment(isFormatted ? start : localStringToUtcString(start));
  const endDate = moment(isFormatted ? end : localStringToUtcString(end));

  return endDate.diff(startDate, 'days');
}

/* ---------------------------------- */

export const dateTimeUtils = {
  setDefaultSettings,
  utcStringToLocalString,
  serverStringToServerOffsetString,
  localStringToServerString,
  serverStringToLocalString,
  localStringToUtcString,
  localDateRangeToUtcString,
  toFormattedString,
  toFormatterUtcString,
  changeDateFormat,
  getEndOfDateString,
  convertHoursToSeconds,
  convertDateToHours,
  convertHoursToCurrentDateTime,
  getCountOfDaysBetweenDates,
};
