import React, { useEffect, useState } from 'react';
import Joyride, { Step, CallBackProps } from 'react-joyride';
import { Beacon } from './modules/Beacon/Beacon';
import { Tooltip } from './modules/Tooltip/Tooltip';
import { Modal } from '@/Components/Modal/Modal';
import styles from './Tour.scss';

export type TourStep = {
  isModal?: boolean;
  callback?: () => void;
  link?: string;
} & Step;

type Props = {
  steps: TourStep[];
  runTour: boolean;
  activeStep: number;
  toggleTour: (val: boolean) => void;
  setTourStep: (val: number) => void;
  continuous?: boolean;
  scrollToFirstStep?: boolean;
};

export function Tour({
  steps,
  runTour,
  activeStep,
  setTourStep,
  toggleTour,
  continuous = true,
  scrollToFirstStep = true,
}: Props) {
  const [tourKey, setTourKey] = useState(0);
  const [stepIndex, setStepIndex] = useState(activeStep);
  const [changeType, setChangeType] = useState<'custom' | 'normal'>('normal');

  useEffect(() => {
    if (activeStep != stepIndex) {
      setStepIndex(activeStep);
      toggleTour(true);
      setChangeType('custom');
    }
  }, [activeStep]);

  const [modalData, setModalData] = useState<{
    visible: boolean;
    data?: TourStep;
  }>({ visible: false });

  const onStepAfter = (index: number, action: string) => {
    toggleTour(false);

    if (changeType == 'normal') {
      const stepIdx = action == 'prev' ? index - 1 : index + 1;
      steps[stepIdx]?.callback?.();
      setStepIndex(stepIdx);
      setTourStep(stepIdx);

      if (action == 'prev' || index != steps.length - 1) {
        setTimeout(() => toggleTour(true), 500);
      }
    } else {
      setTimeout(() => toggleTour(true), 500);
      setChangeType('normal');
    }
  };

  const handleCallback = ({ type, index, action }: CallBackProps) => {
    switch (type) {
      case 'tour:start': {
        if (steps[0]?.callback) {
          toggleTour(false);
          steps[0]?.callback?.();
          setTimeout(() => toggleTour(true), 500);
        }
        break;
      }

      case 'tour:end': {
        setTourStep(0);
        setStepIndex(0);
        setTourKey(tourKey + 1);
        toggleTour(false);
        break;
      }

      case 'tooltip': {
        if (steps[index].isModal) {
          toggleTour(false);
          setModalData({ visible: true, data: steps[index] });
        }
        break;
      }

      case 'step:before': {
        const target = steps[index]?.target as string;
        document.querySelector(target)?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        break;
      }

      case 'step:after': {
        onStepAfter(index, action);
        break;
      }
    }
  };

  return (
    <>
      <Joyride
        key={tourKey}
        // disableOverlayClose={true}
        callback={handleCallback}
        continuous={continuous}
        run={runTour}
        steps={steps}
        stepIndex={stepIndex}
        beaconComponent={Beacon}
        tooltipComponent={Tooltip}
        scrollToFirstStep={scrollToFirstStep}
        disableScrolling
        styles={{
          options: {
            zIndex: 1000,
            arrowColor: getComputedStyle(document.body).getPropertyValue('--block-light-bg-color'),
          },
        }}
      />
      <Modal
        className={styles.modal}
        isOpen={modalData.visible}
        onClose={() => {
          setStepIndex(stepIndex + 1);
          setModalData({ visible: false });
          toggleTour(true);
        }}
      >
        <h2>{modalData.data?.title}</h2>
        {modalData.data?.content}
      </Modal>
    </>
  );
}
