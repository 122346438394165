import React, { useCallback, useState } from 'react';
import { useLocalization } from '@/Hooks';
import { CopyIcon, LockIcon, WarningIcon } from '@/Static';
import styles from './AddressForm.scss';
import cn from 'classnames';
import { FieldDeclaration } from '@/Types';
import { AddressType, CountryCode } from '@/Enums';
import { LogisticsApi } from '@/Api/Logistics';
import { Toasts } from '@/Components/Toast/Toast';
import { Label } from '@/Components/Controls/Label/Label';
import { FormValue } from '@/Components/Controls/FormValue/FormValue';
import { ModalDialog } from '@/Components/ModalDialog/ModalDialog';
import { useAddressActions } from './hooks/useAddressActions';
import { countryTypeUtils } from '@/Utils/CountryTypesUtils';
import { useSelector } from 'react-redux';
import { globalSelectors } from '@/Redux/Global/selectors';
import { salesPageSelectors } from '@/Pages/Sales/services';
import { orderTableRowUtils } from '@/Types/OrderTableRowUtils';
import { Tooltip } from '@/Components/Tooltip/Tooltip';

export type AddressFields<TData> = {
  companyName: FieldDeclaration<TData>;
  firstName: FieldDeclaration<TData>;
  lastName: FieldDeclaration<TData>;
  additionalLine: FieldDeclaration<TData>;
  street: FieldDeclaration<TData>;
  houseNo?: FieldDeclaration<TData>;
  postalCode: FieldDeclaration<TData>;
  cityName: FieldDeclaration<TData>;
  countryCode: FieldDeclaration<TData>;
  countryName: FieldDeclaration<TData>;
};

type Props<TData> = {
  data: AddressFields<TData>;
  addressType: AddressType;
  addressEditDisabled?: string;
  orderId: number | undefined;
  needWarningIcon?: boolean;
  needSplitStreetAndHouse?: boolean;
};

export const AddressForm = <TData extends object>(props: Props<TData>) => {
  const {
    data: {
      street,
      firstName,
      lastName,
      additionalLine,
      cityName,
      companyName,
      countryCode,
      // countryName,
      postalCode,
      houseNo,
    },
    addressType,
    orderId,
    needWarningIcon,
    needSplitStreetAndHouse = true,
    addressEditDisabled,
  } = props;

  const {
    buttons: { Yes, No },
    salesPage: {
      GeneralInfo,
      Dialogs: { LockAddressEditDialog },
    },
    notifications: { CopyAddressSuccess },
  } = useLocalization();

  const translation = useSelector(globalSelectors.translation);
  const [addressDisabledDialogVisible, setAddressDisabledDialogVisible] = useState(false);
  const closeAddressDisabledDialog = useCallback(() => setAddressDisabledDialogVisible(false), []);
  const { changeAddressDisabledState } = useAddressActions(closeAddressDisabledDialog, orderId);

  const [streetName, houseNumber] = street?.currentValue.split(/\s(?=\d.+)/) ?? [];

  const selectedSale = useSelector(salesPageSelectors.selectedSale);
  const isInvoiceCreated = selectedSale?.currentInvoiceNumber != null;

  // Todo: Add shipping label check after cancel shipping label will be added
  const isShippingLabelCreated = false;
  const isOriginalShippingModified = orderTableRowUtils.isOriginalShippingModified(selectedSale);
  const needDisableAddressEditing = isInvoiceCreated || isShippingLabelCreated;

  return (
    <div className={styles.wrapper}>
      {orderId && addressDisabledDialogVisible && (
        <ModalDialog
          title={LockAddressEditDialog.title}
          text={
            addressEditDisabled
              ? isOriginalShippingModified
                ? LockAddressEditDialog.unlockWithLostText
                : LockAddressEditDialog.unlockText
              : LockAddressEditDialog.lockText
          }
          onOk={() => changeAddressDisabledState(Boolean(addressEditDisabled))}
          onClose={closeAddressDisabledDialog}
          cancelButtonText={No}
          okButtonText={Yes}
        />
      )}
      <div className={styles.inputs__wrapper}>
        <Label text={GeneralInfo.Address.Company} className={styles.form__label} />
        <div className={styles.form__inputWrapper}>
          <FormValue value={companyName?.currentValue} />
        </div>

        <Label
          text={GeneralInfo.Address.FirstNameLastName}
          className={cn(styles.form__label, styles.form__label_padding)}
        />
        <div className={cn(styles.form__inputWrapper, styles.form__inputContainer)}>
          <FormValue value={firstName?.currentValue} />
          <FormValue value={lastName?.currentValue} />
        </div>

        <Label text={GeneralInfo.Address.Additional} className={cn(styles.form__label, styles.form__label_padding)} />
        <div className={cn(styles.form__inputWrapper, styles.form__inputAdditional)}>
          <FormValue value={additionalLine?.currentValue} />
        </div>

        {needSplitStreetAndHouse && (
          <>
            <Label text={GeneralInfo.Address.StreetNo} className={cn(styles.form__label, styles.form__label_padding)} />
            <div className={cn(styles.form__inputWrapper, styles.addressStreetFields)}>
              <FormValue value={streetName} />
              <FormValue value={houseNumber ?? houseNo?.currentValue} />
            </div>
          </>
        )}

        {!needSplitStreetAndHouse && (
          <>
            <Label text={GeneralInfo.Address.StreetNo} className={cn(styles.form__label, styles.form__label_padding)} />
            <div className={cn(styles.form__inputWrapper, styles.addressStreetFields)}>
              <FormValue value={street?.currentValue} />
              <FormValue value={houseNo?.currentValue} />
            </div>
          </>
        )}

        <Label text={GeneralInfo.Address.ZipCity} className={styles.form__label} />
        <div className={cn(styles.form__inputWrapper, styles.addressCityFields)}>
          <FormValue value={postalCode?.currentValue} />
          <FormValue value={cityName?.currentValue} />
        </div>

        <Label text={GeneralInfo.Address.Country} className={cn(styles.form__label)} />
        <div className={cn(styles.form__inputWrapper, styles.addressCityFields)}>
          <FormValue value={countryCode?.currentValue} />
          <FormValue
            value={countryTypeUtils.getCountryNameByCountryCode(countryCode?.currentValue as CountryCode, translation)}
          />
        </div>
      </div>

      <div className={styles.icons__wrapper}>
        <CopyIcon
          onClick={() => {
            if (!orderId) return;

            LogisticsApi.getFormattedAddress(orderId, addressType)
              .then((response) => {
                return navigator.clipboard.writeText(response).then(() => {
                  Toasts.showSuccess({
                    text: CopyAddressSuccess.text,
                  });
                });
              })
              .then();
          }}
        />
        <Tooltip
          className={styles.icon}
          text={GeneralInfo.Address.UnlockNotPermitted}
          shouldShowOnHover={needDisableAddressEditing}
        >
          <LockIcon
            locked={Boolean(addressEditDisabled)}
            disabled={needDisableAddressEditing}
            onClick={() => {
              if (orderId && !needDisableAddressEditing) setAddressDisabledDialogVisible(true);
            }}
          />
        </Tooltip>
        {needWarningIcon && <WarningIcon className={styles.form__warningIcon} />}
      </div>
    </div>
  );
};
