import React, { useMemo } from 'react';
import { TourStep } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useAccountSettings() {
  const {
    tours: {
      AccountSettings: { Default, AccountSettings },
    },
  } = useLocalization();

  const pageTour = useMemo<TourStep[]>(() => {
    return [
      {
        content: <div>{Default.Step1.Text}</div>,
        placement: 'auto',
        target: '.regionalSettingsBtn',
        title: Default.Step1.Title,
        disableBeacon: true,
        link: 'https://www.google.com/',
      },
      {
        content: <div>{Default.Step2.Text}</div>,
        placement: 'auto',
        target: '.paymentMethodsBtn',
        title: Default.Step2.Title,
        disableBeacon: true,
        link: 'https://www.google.com/',
      },
      {
        content: <div>{Default.Step3.Text}</div>,
        placement: 'auto',
        target: '.basicSettings',
        title: Default.Step3.Title,
        disableBeacon: true,
        link: 'https://www.google.com/',
      },
      {
        content: <div>{Default.Step4.Text}</div>,
        placement: 'auto',
        target: '.additionalSettings',
        title: Default.Step4.Title,
        disableBeacon: true,
        link: 'https://www.google.com/',
      },
      {
        content: <div>{Default.Step5.Text}</div>,
        placement: 'auto',
        target: '.additionalEditableSettings',
        title: Default.Step5.Title,
        disableBeacon: true,
        link: 'https://www.google.com/',
      },
      {
        content: <div>{Default.Step6.Text}</div>,
        placement: 'auto',
        target: '.basicParameters',
        title: Default.Step6.Title,
        disableBeacon: true,
        link: 'https://www.google.com/',
      },
    ];
  }, [Default]);

  const accountSettingsTour = useMemo<TourStep[]>(() => {
    return [
      {
        content: <div>{AccountSettings.Step1.Text}</div>,
        placement: 'auto',
        target: '.basicTimezoneSettings',
        title: AccountSettings.Step1.Title,
        disableBeacon: true,
        link: 'https://www.google.com/',
      },
      {
        content: <div>{AccountSettings.Step2.Text}</div>,
        placement: 'auto',
        target: '.extraTimezoneSettings',
        title: AccountSettings.Step2.Title,
        disableBeacon: true,
        link: 'https://www.google.com/',
      },
      {
        content: <div>{AccountSettings.Step3.Text}</div>,
        placement: 'auto',
        target: '.currencyTable',
        title: AccountSettings.Step3.Title,
        disableBeacon: true,
        link: 'https://www.google.com/',
      },
    ];
  }, [AccountSettings]);

  return {
    pageTour,
    accountSettingsTour,
  };
}
