import React, { FC } from 'react';
import { ClassNameProps } from '@/Types';
import styles from './SideMenu.scss';
import cn from 'classnames';
import { useHistory } from 'react-router';
import { PagesRouting } from '@/Routing';
import { useLocalization, useTriggeredValue, useUserHasBusinessSegment, useUserHasMarketSegment } from '@/Hooks';
import { getModuleItemClassNames } from './Utils';
import { SynchModal } from './modules/SynchModal';
import { Icons, ModuleIcons } from '@/Static/IconsRes';
import { NotifyCircle } from '@/Components/NotifyCircle/NotifyCircle';
import { useSelector } from 'react-redux';
import { salesPageSelectors } from '@/Pages/Sales/services';
import { purchasesPageSelectors } from '@/Pages/Purchases/services';
import { useSideMenuItems } from './hooks/useSideMenuItems';

type Props = ClassNameProps;

const NOTIFICATION_OFFSETS = {
  right: 13,
  top: 2,
};

export const SideMenu: FC<Props> = ({ className }: Props) => {
  const {
    modules: { Synchronize, Administration },
  } = useLocalization();

  const newSalesCount = useSelector(salesPageSelectors.newSalesCount);
  const updatedOrdersExists = useSelector(salesPageSelectors.updatedOrdersExists);
  const newPurchasesCount = useSelector(purchasesPageSelectors.newPurchasesCount);
  const updatedPurchasesExists = useSelector(purchasesPageSelectors.updatedPurchasesExists);

  const isMarketUser = useUserHasMarketSegment();
  const isBusinessUser = useUserHasBusinessSegment();

  const history = useHistory();
  const pathName = history.location.pathname;

  const { value: isSynchModalOpen, on: openSynchModal, off: closeSynchModal } = useTriggeredValue(false, true, false);

  const menuItems = useSideMenuItems();

  return (
    <div className={cn(className, styles.wrapper)}>
      {isSynchModalOpen && <SynchModal closeModalWindow={closeSynchModal} />}
      <div className={styles.logo} onClick={() => history.push(PagesRouting.MainPages.SalesPage.Sales)}>
        <img src={Icons.Logo} alt="logo" />
      </div>

      <label className={styles.modules__item} onClick={openSynchModal} data-testid={'synchronize'}>
        <img src={ModuleIcons.Synchronize} alt="Synchronize" className={styles.modules__image} />
        <span className={styles.modules__text}>{Synchronize}</span>
      </label>

      <div className={cn(styles.menuBlock)}>
        <div>
          {menuItems.map((item, idx) => (
            <label
              key={item.title}
              className={getModuleItemClassNames(item.classRoutes, pathName)}
              data-testid={item.dataTestId}
              onClick={() => history.push(item.route)}
              id={item?.moduleId}
            >
              {item.route === PagesRouting.MainPages.SalesPage.Sales && (
                <NotifyCircle
                  displayNumber={newSalesCount}
                  offsets={NOTIFICATION_OFFSETS}
                  showEmpty={updatedOrdersExists}
                />
              )}

              {item.route === PagesRouting.MainPages.PurchasesPage && (
                <NotifyCircle
                  displayNumber={newPurchasesCount}
                  offsets={NOTIFICATION_OFFSETS}
                  showEmpty={updatedPurchasesExists}
                />
              )}

              <div className={styles.modules__imageBlock}>
                <img src={item.icon} alt={item.title} className={styles.modules__image} />
                <img
                  src={item.settingsIcon}
                  alt={item.title}
                  className={cn(styles.modules__imageSettings, { [styles.salesSettingsIcon]: idx == 1 })}
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(item?.settingsRoute);
                  }}
                  id={item?.moduleSettingsId}
                />
              </div>
              <span className={styles.modules__text}>{item.title}</span>
            </label>
          ))}
        </div>

        {(isBusinessUser || isMarketUser) && (
          <label
            className={cn(getModuleItemClassNames(PagesRouting.AdminPages.Admin, pathName), styles.modules__admin)}
            onClick={() => history.push(PagesRouting.AdminPages.Admin)}
          >
            <img
              src={ModuleIcons.AdminModule}
              width={34}
              height={34}
              alt="AdministrationModule"
              className={styles.modules__image}
            />
            <span className={styles.modules__text}>{Administration}</span>
          </label>
        )}
      </div>
    </div>
  );
};
