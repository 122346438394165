import React from 'react';
import { TourStep } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useDocumentCategoriesSettings() {
  const {
    tours: {
      OnlineArchiveSettings: { DocumentCategories },
    },
  } = useLocalization();

  const pageTour: TourStep[] = [
    {
      content: <div>{DocumentCategories.Step1.Text}</div>,
      placement: 'auto',
      target: '#documentCategories',
      title: DocumentCategories.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{DocumentCategories.Step2.Text}</div>,
      placement: 'auto',
      target: '#categoryList',
      title: DocumentCategories.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{DocumentCategories.Step3.Text}</div>,
      placement: 'right',
      target: '#addItemButton',
      title: DocumentCategories.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{DocumentCategories.Step4.Text}</div>,
      placement: 'auto',
      target: '#documentTypes',
      title: DocumentCategories.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{DocumentCategories.Step5.Text}</div>,
      placement: 'auto',
      target: '#typeName',
      title: DocumentCategories.Step5.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      styles: {
        spotlight: {
          marginTop: '-10px',
          marginLeft: '-10px',
        },
      },
    },
    {
      content: <div>{DocumentCategories.Step6.Text}</div>,
      placement: 'top',
      target: '#removalCount',
      title: DocumentCategories.Step6.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      styles: {
        spotlight: {
          marginTop: '-10px',
        },
      },
    },
    {
      content: <div>{DocumentCategories.Step7.Text}</div>,
      placement: 'auto',
      target: '#removalDateType',
      title: DocumentCategories.Step7.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{DocumentCategories.Step8.Text}</div>,
      placement: 'auto',
      target: '#typeCrud',
      title: DocumentCategories.Step8.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  return {
    pageTour,
  };
}
