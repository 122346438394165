import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CopyTemplateRequest,
  CopyTemplateResponse,
  DocumentTemplateApi,
  GetAllDocumentTemplateResponse,
  RestoreTemplateRequest,
  RestoreTemplateResponse,
  UpdateDocumentTemplateRequest,
  UpdateDocumentTemplateResponse,
} from '@/Api/DocumentTemplates';
import { DocumentTemplateType } from '@/Enums/DocumentTemplateType';
import { DeleteDocumentTemplateResponse } from '@/Api/DocumentTemplates/Responses/DeleteDocumentTemplateResponse';
import { IPrinterInfo } from '@/Electron/services/PrintService/IPrintService';
import { documentStoreService, printService } from '@/Electron';
import { Nullable } from '@/Types';
import { IDocumentPrinterInfo } from '@/Electron/services/DocumentStoreService/IDocumentStoreService';

const getAllDocumentTemplate = createAsyncThunk<GetAllDocumentTemplateResponse>(
  'documentTemplateSettings/getAll',
  async () => {
    return DocumentTemplateApi.getAllDocumentTemplates(DocumentTemplateType.Document);
  },
);

const updateDocumentTemplate = createAsyncThunk<UpdateDocumentTemplateResponse, UpdateDocumentTemplateRequest>(
  'documentTemplateSettings/update',
  async (request: UpdateDocumentTemplateRequest) => {
    return DocumentTemplateApi.updateDocumentTemplate(request);
  },
);

const deleteDocumentTemplate = createAsyncThunk<DeleteDocumentTemplateResponse, string>(
  'documentTemplateSettings/delete',
  async (guid: string) => {
    return DocumentTemplateApi.deleteDocumentTemplate(guid);
  },
);

const restoreDocumentTemplate = createAsyncThunk<RestoreTemplateResponse, RestoreTemplateRequest>(
  'documentTemplateSettings/restore',
  async (request: RestoreTemplateRequest) => {
    return DocumentTemplateApi.restoreDocumentTemplate(request);
  },
);

const getAllPrinters = createAsyncThunk<IPrinterInfo[]>('documentTemplateSettings/getPrinters', async () => {
  return printService.getPrinters();
});

const getDefaultPrinter = createAsyncThunk<Nullable<IPrinterInfo>>(
  'documentTemplateSettings/getDefaultPrinter',
  async () => {
    return printService.getDefaultPrinter();
  },
);

const getSavedPrinterInfo = createAsyncThunk<Nullable<IDocumentPrinterInfo>, string>(
  'documentTemplateSettings/getSavedPrinterInfo',
  async (guid: string) => {
    return documentStoreService.getByDocumentGuid(guid);
  },
);

const savePrinterInfo = createAsyncThunk<IDocumentPrinterInfo, IDocumentPrinterInfo>(
  'documentTemplateSettings/savePrinterInfoRequest',
  async (documentPrinterInfo: IDocumentPrinterInfo) => {
    documentStoreService.addOrUpdate(documentPrinterInfo);
    return documentPrinterInfo;
  },
);

const removePrinterInfo = createAsyncThunk(
  'documentTemplateSettings/removePrinterInfoRequest',
  async (documentGuid: string) => {
    documentStoreService.removeByDocumentGuid(documentGuid);
  },
);

const copyDocumentTemplate = createAsyncThunk<CopyTemplateResponse, CopyTemplateRequest>(
  'documentTemplateSettings/copy',
  async (request: CopyTemplateRequest) => {
    return DocumentTemplateApi.copyDocumentTemplate(request);
  },
);

export const asyncActions = {
  getAllDocumentTemplate,
  deleteDocumentTemplate,
  updateDocumentTemplate,
  restoreDocumentTemplate,
  getAllPrinters,
  getDefaultPrinter,
  getSavedPrinterInfo,
  savePrinterInfo,
  removePrinterInfo,
  copyDocumentTemplate,
};
