import { ApiRoutingUtils } from '@/Routing';
import {
  CheckLogisticContractRequest,
  CheckLogisticContractResponse,
  CreateLogisticContractRequest,
  CreateLogisticContractResponse,
  GetAllLogisticContractResponse,
  UpdateLogisticContractRequest,
  UpdateLogisticContractResponse,
} from '../LogisticContracts';
import { rest } from '@/Api/Rest';
import { GetActiveLogisticTypesResponse } from './GetActiveLogisticTypesResponse';
import { GetGroupedUserWarehouseResponse } from '@/Api/LogisticContracts/GetGroupedUserWarehouseResponse';
import { UpdateLogisticContractStateRequest } from '@/Api/LogisticContracts/UpdateLogisticContractStateRequest';
import { UpdateLogisticContractStateResponse } from '@/Api/LogisticContracts/UpdateLogisticContractStateResponse';
import { GetLogisticContractResponse } from '@/Api/LogisticContracts/GetLogisticContractResponse';

const url = ApiRoutingUtils.createUrl('logistic-contracts');
const warehouseUrl = ApiRoutingUtils.createUrl('user-warehouse-logistic-contracts');

async function getAllLogisticContracts(): Promise<GetAllLogisticContractResponse> {
  return rest.get(`${url}`);
}

async function getLogisticContract(id: number): Promise<GetLogisticContractResponse> {
  return rest.get(`${url}/${id}`);
}

async function createLogisticContract(request: CreateLogisticContractRequest): Promise<CreateLogisticContractResponse> {
  return rest.post(url, request);
}

async function updateLogisticContract(request: UpdateLogisticContractRequest): Promise<UpdateLogisticContractResponse> {
  return rest.put(url, request);
}

async function deleteLogisticContract(id: number): Promise<number> {
  return rest.delete(`${url}/${id}`, null);
}

async function checkConnection(request: CheckLogisticContractRequest): Promise<CheckLogisticContractResponse> {
  return rest.post(`${url}/check-connection`, request);
}

async function getActiveLogisticTypes(): Promise<GetActiveLogisticTypesResponse> {
  return rest.get(`${url}/active`);
}

async function getGroupedUserWarehouses(): Promise<GetGroupedUserWarehouseResponse> {
  return rest.get(`${warehouseUrl}/grouped-by-mandat-countries`);
}

async function updateLogisticContractState(
  request: UpdateLogisticContractStateRequest,
): Promise<UpdateLogisticContractStateResponse> {
  return rest.put(`${url}/update-state`, request);
}

export const LogisticContractsApi = {
  getAllLogisticContracts,
  getLogisticContract,
  createLogisticContract,
  updateLogisticContract,
  deleteLogisticContract,
  checkConnection,
  getActiveLogisticTypes,
  getGroupedUserWarehouses,
  updateLogisticContractState,
};
