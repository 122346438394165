import { useCallback, useMemo } from 'react';
import { LogisticTypeKeys, ShippingServiceType } from '@/Enums';
import dpdLogo from '@/Static/Logos/dpd_logo.png';
import dhl from '@/Static/Logos/dhl.png';
import gls from '@/Static/Logos/gls.png';
import upsGermany from '@/Static/Logos/upc_germany.png';
import deutschePost from '@/Static/Logos/deutsche_post.png';
import hermes from '@/Static/Logos/hermes.png';
import shipCheaper from '@/Static/Logos/shipCheaper.png';
import { Icons } from '@/Static/IconsRes';
import { useLocalization } from '@/Hooks';

export const useLogisticProductIcons = () => {
  const iconsMap = useMemo((): { [key in LogisticTypeKeys]: string } => {
    return {
      [LogisticTypeKeys.ShipCheaper]: shipCheaper,
      [LogisticTypeKeys.Dpd]: dpdLogo,
      [LogisticTypeKeys.Dhl]: dhl,
      [LogisticTypeKeys.Hermes]: hermes,
      [LogisticTypeKeys.Gls]: gls,
      [LogisticTypeKeys.Ups]: upsGermany,
      [LogisticTypeKeys.DeutschePost]: deutschePost,
      [LogisticTypeKeys.DhlExpress]: dhl,
      [LogisticTypeKeys.DeutschePostMarke]: deutschePost,
    };
  }, []);

  const getLogisticProductIconByType = useCallback(
    (logisticType: LogisticTypeKeys) => {
      return iconsMap[logisticType];
    },
    [iconsMap],
  );

  return {
    getLogisticProductIconByType,
  };
};

export const useShippingServiceIcons = () => {
  const {
    modalWindows: {
      orderLinkWithProduct: {
        ShipCheaperContract: { Curier, Pickup },
      },
    },
  } = useLocalization();

  const iconsMap = useMemo((): { [key in ShippingServiceType]: { type: string; icon: string }[] } => {
    return {
      [ShippingServiceType.PickupToPickup]: [
        { type: Pickup, icon: Icons.Pickup },
        { type: Pickup, icon: Icons.Pickup },
      ],
      [ShippingServiceType.PickupToCurier]: [
        { type: Pickup, icon: Icons.Pickup },
        { type: Curier, icon: Icons.Curier },
      ],
      [ShippingServiceType.CurierToCurier]: [
        { type: Curier, icon: Icons.Curier },
        { type: Curier, icon: Icons.Curier },
      ],
      [ShippingServiceType.CurierToPickup]: [
        { type: Curier, icon: Icons.Curier },
        { type: Pickup, icon: Icons.Pickup },
      ],
    };
  }, [Curier, Pickup]);

  const getShippingServiceIconByType = useCallback(
    (shippingService: ShippingServiceType) => {
      return iconsMap[shippingService];
    },
    [iconsMap],
  );

  return {
    getShippingServiceIconByType,
  };
};
