import React from 'react';
import { TourStep } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function usePackageSettings() {
  const {
    tours: {
      PackageSettings: { Default, Package },
    },
  } = useLocalization();

  const pageTour: TourStep[] = [
    {
      content: <div>{Default.Step1.Text}</div>,
      placement: 'auto',
      target: '#packageTitle',
      title: Default.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Default.Step2.Text}</div>,
      placement: 'auto',
      target: '#packageTable',
      title: Default.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Default.Step3.Text}</div>,
      placement: 'auto',
      target: '#crudExtended',
      title: Default.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Default.Step4.Text}</div>,
      placement: 'auto',
      target: '.dx-toolbar-after',
      title: Default.Step4.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
      spotlightPadding: 0,
      styles: {
        spotlight: {
          maxWidth: '500px',
        },
      },
    },
    {
      content: <div>{Default.Step5.Text}</div>,
      placement: 'auto',
      target: '.dx-dropdownmenu-button',
      title: Default.Step5.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Default.Step6.Text}</div>,
      placement: 'auto',
      target: '#packageForm',
      title: Default.Step6.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  const packageTour: TourStep[] = [
    {
      content: <div>{Package.Step1.Text}</div>,
      placement: 'auto',
      target: '#packageSettingsTitle',
      title: Package.Step1.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Package.Step2.Text}</div>,
      placement: 'auto',
      target: '#packageModalFields',
      title: Package.Step2.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: <div>{Package.Step3.Text}</div>,
      placement: 'auto',
      target: '#packageModalStatus',
      title: Package.Step3.Title,
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  return {
    pageTour,
    packageTour,
  };
}
