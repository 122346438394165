import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentTemplateModel } from '@/Models';
import { Nullable, Pair } from '@/Types';
import { documentStoreService } from '@/Electron';
import { IPrinterInfo } from '@/Electron/services/PrintService/IPrintService';
import { DocumentTemplateModalType } from '@/Enums';
import { documentTemplateMapping } from '@/Mapping';
import { DocumentTemplateType } from '@/Enums/DocumentTemplateType';
import { asyncActions } from './asyncActions';

export type DocumentTemplateSettingsState = {
  modalWindowType: Nullable<DocumentTemplateModalType>;
  selectedTemplate: Nullable<DocumentTemplateModel>;
  templates: DocumentTemplateModel[];
  printers: Pair<any>[];
  defaultPrinter: Nullable<IPrinterInfo>;
  selectedPrinterName: string | undefined;
  isPrintPreview: boolean;
};

const initialState: DocumentTemplateSettingsState = {
  modalWindowType: null,
  selectedTemplate: null,
  templates: [],
  printers: [],
  defaultPrinter: null,
  selectedPrinterName: '',
  isPrintPreview: true,
};

const documentTemplateSettingsSlice = createSlice({
  name: 'documentTemplateSettings',
  initialState,
  reducers: {
    setSelectedTemplate(state, action: PayloadAction<Nullable<DocumentTemplateModel>>) {
      state.selectedTemplate = action.payload;
    },
    setUpdatedTemplate(state, action: PayloadAction<DocumentTemplateModel>) {
      const index = state.templates.findIndex(({ guid }) => guid == action.payload.guid);
      if (index == -1) {
        state.templates.unshift(action.payload);
      } else {
        state.templates = state.templates.map((x) => {
          return x.guid === action.payload.guid ? action.payload : x;
        });
      }
      state.selectedTemplate = action.payload;
    },
    setDirectoryPath(state, action: PayloadAction<string>) {
      if (state.selectedTemplate?.documentTemplateSettings) {
        state.selectedTemplate.documentTemplateSettings.directoryPath = action.payload;
      }
    },
    openModal(state, action: PayloadAction<DocumentTemplateModalType>) {
      state.modalWindowType = action.payload;
    },
    closeModal(state) {
      state.modalWindowType = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getAllDocumentTemplate.fulfilled, (state, action) => {
        state.templates = [...action.payload.templates].sort((a, b) => a.name.localeCompare(b.name));
        const guids = state.templates.map((x) => x.guid);
        documentStoreService.upToDateByDocuments(guids);
      })
      .addCase(asyncActions.getAllPrinters.fulfilled, (state, action) => {
        const printers = action.payload;
        state.printers = printers.map((value) => {
          return {
            key: value.name,
            value: value.displayName,
          };
        });
        const names = printers.map((x) => x.name);
        documentStoreService.upToDateByDocuments(names);
      })
      .addCase(asyncActions.deleteDocumentTemplate.fulfilled, (state, action) => {
        state.templates = state.templates.filter((x) => x.guid !== action.payload.guid);
      })
      .addCase(asyncActions.getDefaultPrinter.fulfilled, (state, action) => {
        state.defaultPrinter = action.payload;
        if (state.selectedPrinterName === '' || state.selectedPrinterName === undefined) {
          state.selectedPrinterName = state.defaultPrinter?.name;
        }
      })
      .addCase(asyncActions.getSavedPrinterInfo.fulfilled, (state, action) => {
        state.selectedPrinterName = action.payload?.printerName ?? state.defaultPrinter?.name;
        state.isPrintPreview = action.payload?.isPreview ?? true;
      })
      .addCase(asyncActions.savePrinterInfo.fulfilled, (state, action) => {
        state.selectedPrinterName = action.payload.printerName;
        state.isPrintPreview = action.payload.isPreview;
      })
      .addCase(asyncActions.restoreDocumentTemplate.fulfilled, (state, action) => {
        state.modalWindowType = null;
        const template = state.templates.find(({ guid }) => guid == action.payload.templateGuid);
        if (template) {
          template.name = action.payload.templateName;
        }
      })
      .addCase(asyncActions.copyDocumentTemplate.fulfilled, (state, action) => {
        state.modalWindowType = null;
        const template = documentTemplateMapping.mappingDocumentTemplateModel(
          action.payload.templateGuid,
          action.payload.templateName,
          DocumentTemplateType.Document,
        );
        state.templates.push(template);
      });
  },
});

const { actions, reducer } = documentTemplateSettingsSlice;

export const documentTemplateActions = actions;
export const documentTemplateReducer = reducer;
export const documentTemplateAsyncActions = asyncActions;
