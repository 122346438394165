import { useDispatch, useSelector } from 'react-redux';
import { salesPageSelectors } from '@/Pages/Sales/services';
import { purchasesPageSelectors } from '@/Pages/Purchases/services';
import { dynamicDataSelectors } from '@/Redux/DynamicData';
import { orderLabelPrintActions, orderLabelPrintModalSelectors } from '@/ModalWindows/OrderLabelPrintModal/services';
import { useLogisticTypesTreeViewOptions } from '@/Hooks/TreeViewOptions';
import { genLogisticTypesDataSelectors } from '@/Redux/DynamicData/GenLogisticTypesData/selector';
import { useCountryOptions } from '@/Enums/CountryTypeHooks';
import { useEffect } from 'react';
import { AppDispatch } from '@/Redux/ConfigureStore';

export function useOrderLabelPrintOptions(isSales: boolean) {
  const dispatch: AppDispatch = useDispatch();

  const selectedOrders = useSelector(
    isSales ? salesPageSelectors.selectedSales : purchasesPageSelectors.selectedPurchases,
  );
  const countryOptions = useCountryOptions();
  const filterStatus = useSelector(orderLabelPrintModalSelectors.filterStatus);
  const filterLogistics = useSelector(orderLabelPrintModalSelectors.filterLogistics);
  const isAllSelectedCreated = useSelector(orderLabelPrintModalSelectors.isAllSelectedCreated);
  const selectedOrderLabelPrintRows = useSelector(orderLabelPrintModalSelectors.selectedOrderLabelPrintRows);
  const logisticTypesOptions = useSelector(dynamicDataSelectors.genLogisticTypesData.logisticTypesOptions);
  const logisticTypesTreeViewOptions = useSelector(genLogisticTypesDataSelectors.logisticTypesTreeViewOptionsMemo);
  const isAllLabelsIsPickup = useSelector(orderLabelPrintModalSelectors.isAllLabelsIsPickup);

  const { typesTreeViewItems: logisticTypesTreeViewItems } = useLogisticTypesTreeViewOptions(
    logisticTypesTreeViewOptions,
    filterLogistics,
  );

  useEffect(() => {
    dispatch(orderLabelPrintActions.setCountryOptions(countryOptions));
  }, [countryOptions]);

  return {
    selectedOrders,
    logisticTypesOptions,
    selectedOrderLabelPrintRows,
    filterStatus,
    isAllSelectedCreated,
    logisticTypesTreeViewItems,
    filterLogistics,
    isAllLabelsIsPickup,
  };
}
