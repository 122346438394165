import { createAsyncThunk } from '@reduxjs/toolkit';
import { GeneratorProductsApi } from '@/Api/GeneratorProducts/GeneratorProductsApi';
import {
  CreateGeneratorProductRequest,
  CreateGeneratorProductResponse,
  GenerateUserProductResponse,
  UpdateGeneratorProductRequest,
  UpdateGeneratorProductResponse,
} from '@/Api/GeneratorProducts';

const getGeneratorProductsRequest = createAsyncThunk('generatorProducts/get-generator-products', async () => {
  return GeneratorProductsApi.getGeneratorProducts();
});

const createGeneratorProductRequest = createAsyncThunk<CreateGeneratorProductResponse, CreateGeneratorProductRequest>(
  'generatorProducts/create-generator-product',
  async (request: CreateGeneratorProductRequest) => {
    const response = await GeneratorProductsApi.createGeneratorProduct(request);
    return response;
  },
);

const updateGeneratorProductRequest = createAsyncThunk<UpdateGeneratorProductResponse, UpdateGeneratorProductRequest>(
  'generatorProducts/update-generator-product',
  async (request: UpdateGeneratorProductRequest) => {
    const response = await GeneratorProductsApi.updateGeneratorProduct(request);
    return response;
  },
);

const deleteGeneratorProductRequest = createAsyncThunk<number, number>(
  'generatorProducts/delete-generator-product',
  async (id: number) => {
    return GeneratorProductsApi.deleteGeneratorProduct(id);
  },
);

const generateProductsRequest = createAsyncThunk<GenerateUserProductResponse, number>(
  'generatorProducts/generate-products',
  async (id: number) => {
    return GeneratorProductsApi.generateProducts(id);
  },
);

export const asyncActions = {
  getGeneratorProductsRequest,
  createGeneratorProductRequest,
  updateGeneratorProductRequest,
  deleteGeneratorProductRequest,
  generateProductsRequest,
};
