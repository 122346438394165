import { MpOrdersMarkingApi } from '@/Api/MpOrdersMarking/MpOrdersMarkingApi';
import { OrderMarker } from '@/Enums';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { salesPageActions } from '@/Pages/Sales/services';

export const useAllPaidForm = (orderIds: number[], isSalesPage: boolean, onClose: () => void) => {
  const dispatch = useDispatch();

  const submit = useCallback(() => {
    MpOrdersMarkingApi.changeOrderPaymentStatus({
      orderIds,
      orderMarker: OrderMarker.NotPaid,
    }).then((response) => {
      // We have not paid filters, so there is nothing to update
      // All will update after bg task will complete
      dispatch(salesPageActions.ordersUpdate(response));

      onClose();
    });
  }, [onClose, orderIds, isSalesPage]);

  return {
    submit,
  };
};
