import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { asyncActions } from '@/Pages/Products/services/transfer/asyncActions';
import { ProductErrorModel, ProductsTransferPointsModel, ProductTransferModel } from '@/Models';
import { TransferModalType } from '@/Pages/Products/types/TransferModalType';
import { productMapping } from '@/Mapping';
import { DataTableSource } from '@/Components/DataTableV2/services/types';
import { ProductTransferTableRow } from '@/Types';
import { productReportMapping } from '@/Mapping/ProductReportMapping';
import { TransferReportTableRow } from '@/Types/TransferReportTableRow';
import { dateTimeUtils } from '@/Utils';

export type TransferState = {
  transferPoints: ProductsTransferPointsModel;
  transferProducts: TransferModalType;
  productReports: {
    reports: TransferReportTableRow[];
    selectedReportIds: number[];
    isDeleteModalOpen: boolean;
  };
  transferErrors: ProductErrorModel[];
};

const initialState: TransferState = {
  transferPoints: { receipentWarehouse: '', senderWarehouse: '' },
  transferProducts: {
    products: [],
    productsDataSource: null,
    isTransferModalOpen: false,
    countOfPendingRequests: 0,
    needDeleteProductSku: null,
    nextReportNumber: null,
  },
  productReports: {
    reports: [],
    selectedReportIds: [],
    isDeleteModalOpen: false,
  },
  transferErrors: [],
};

const transferSlice = createSlice({
  name: 'transfer',
  initialState,
  reducers: {
    setTransferPoints(state, action: PayloadAction<ProductsTransferPointsModel>) {
      state.transferPoints = action.payload;
    },
    setVisibleTransferModal(state, action: PayloadAction<boolean>) {
      state.transferProducts.isTransferModalOpen = action.payload;
    },
    clearTransferProducts(state) {
      state.transferProducts = {
        ...state.transferProducts,
        products: [],
        productsDataSource: null,
        countOfPendingRequests: 0,
      };
    },
    addTransferProducts(state, action: PayloadAction<ProductTransferModel>) {
      const { product, quantity } = action.payload;
      const productIndex = state.transferProducts.products.findIndex((item) => item.sku == product.sku);

      if (state.transferProducts.countOfPendingRequests != 0) {
        state.transferProducts.countOfPendingRequests = state.transferProducts.countOfPendingRequests - 1;
      }

      if (productIndex != -1) {
        state.transferProducts.products = state.transferProducts.products.map((item, idx) => {
          if (idx == productIndex) return { ...item, quantity: Number(item.quantity) + Number(quantity) };
          return item;
        });
      } else {
        state.transferProducts.products = [
          ...state.transferProducts.products.map((item) => ({ ...item, isLastModified: false })),
          productMapping.convertProductToProductTransferTableRow(product, quantity, true),
        ];
      }

      state.transferProducts.productsDataSource = new DataTableSource<ProductTransferTableRow>(
        'sku',
        state.transferProducts.products,
      );
    },
    changeTransferProduct(state, action: PayloadAction<{ sku: string; data: ProductTransferTableRow }>) {
      const { sku, data } = action.payload;

      state.transferProducts.products = state.transferProducts.products.map((item) => {
        if (item.sku == sku) return { ...item, ...data, isLastModified: true };
        return { ...item, isLastModified: false };
      });

      state.transferProducts.productsDataSource = new DataTableSource<ProductTransferTableRow>(
        'sku',
        state.transferProducts.products,
      );
    },
    setNeedDeleteProductSku(state, action: PayloadAction<string>) {
      state.transferProducts.needDeleteProductSku = action.payload;
    },
    deleteTransferProduct(state, action: PayloadAction<{ sku: string }>) {
      const { sku } = action.payload;
      state.transferProducts.products = state.transferProducts.products.filter((item) => item.sku != sku);
      state.transferProducts.productsDataSource = new DataTableSource<ProductTransferTableRow>(
        'sku',
        state.transferProducts.products,
      );
    },
    changeCountOfPendingRequests(state, action: PayloadAction<number>) {
      state.transferProducts.countOfPendingRequests = action.payload;
    },

    // Reports
    setVisibleDeleteReportModal(state, action: PayloadAction<boolean>) {
      state.productReports.isDeleteModalOpen = action.payload;
    },
    setSelectedReports: (state, action: PayloadAction<TransferReportTableRow[]>) => {
      state.productReports.selectedReportIds = action.payload.map((item) => item.id);
    },
  },
  extraReducers: (builder) => {
    builder
      // Getting reports
      .addCase(asyncActions.getProductReports.fulfilled, (state, action) => {
        state.productReports.reports = action.payload.productReports.map(productReportMapping.modelToTableRow);
      })
      .addCase(asyncActions.deleteProductReports.fulfilled, (state, action) => {
        state.productReports.reports = state.productReports.reports.filter((x) => !action.payload.includes(x.id));
      })
      // Getting transfer errors
      .addCase(asyncActions.getProductTransferErrors.fulfilled, (state, action) => {
        state.transferErrors = action.payload.errorModels.map((item) => ({
          ...item,
          createdDate: dateTimeUtils.serverStringToServerOffsetString(item.createdDate),
        }));
      })
      .addCase(asyncActions.clearProductTransferErrors.fulfilled, (state) => {
        state.transferErrors = [];
      })
      .addCase(asyncActions.getNextReportNumber.fulfilled, (state, action) => {
        state.transferProducts.nextReportNumber = action.payload.nextReportNumber;
      });
  },
});

const { actions, reducer } = transferSlice;

export const transferPageActions = actions;
export const transferPageReducer = reducer;
export const transferAsyncActions = asyncActions;
