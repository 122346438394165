import { MpOrdersMarkingApi } from '@/Api/MpOrdersMarking/MpOrdersMarkingApi';
import { ChangeOrderPaymentStatusByMarkerRequest } from '@/Api/MpOrdersMarking/Requests';
import { DateFormatType, OrderMarker, OrderStatusFilterGroups } from '@/Enums';
import { useForm } from '@/Hooks/useFormWrapper';
import { useRequired } from '@/Hooks/Validation';
import { purchasesPageAsyncActions } from '@/Pages/Purchases/services';
import { salesPageActions } from '@/Pages/Sales/services';
import { dateTimeUtils } from '@/Utils/DateTimeUtils';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

type FormData = ChangeOrderPaymentStatusByMarkerRequest;

export const useAllNotPaidForm = (orderIds: number[], isSalesPage: boolean, onClose: () => void) => {
  const dispatch = useDispatch();

  const methods = useForm<FormData>({
    defaultValues: {
      orderMarker: OrderMarker.Paid,
      orderIds,
      paymentDate: dateTimeUtils.toFormattedString(new Date(), DateFormatType.LOCAL_DATE_TIME),
    },
  });

  const required = useRequired();

  const submit = useCallback(
    (data: FormData) => {
      MpOrdersMarkingApi.changeOrderPaymentStatus({
        ...data,
        paymentDate: dateTimeUtils.localStringToUtcString(data?.paymentDate),
      }).then((response) => {
        if (isSalesPage) {
          dispatch(
            salesPageActions.removeOrdersFromStateByCurrentFilters({
              orderIds: data.orderIds,
              filters: OrderStatusFilterGroups.NotPaidGroup,
            }),
          );

          setTimeout(() => {
            dispatch(salesPageActions.ordersUpdate(response));
          }, 100);
        } else {
          dispatch(purchasesPageAsyncActions.getPurchasesWithCurrentFilters());
        }

        onClose();
      });
    },
    [onClose, isSalesPage, dispatch],
  );

  return {
    methods,
    submit: methods.handleSubmit(submit),
    rules: {
      required,
    },
  };
};
