import React from 'react';
import { ClassNameProps } from '@/Types';
import cn from 'classnames';
import styles from './TabItem.scss';

type Props = {
  isActive: boolean;
  onClick: (idx: number) => void;
  tabName: string;
  idx: number;
  id?: string;
} & ClassNameProps;

export function TabItem({ className, isActive, tabName, idx, onClick, id }: Props) {
  return (
    <div
      id={id}
      className={cn(className, styles.tabsItem, {
        [styles.tabsItem__active]: isActive,
        [styles.tabsItem_with_divider]: !isActive,
      })}
      onClick={() => onClick(idx)}
      title={tabName}
    >
      {tabName}
    </div>
  );
}
